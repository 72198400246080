import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';


const HomeSliderDesiresWrapper = styled.div`
  padding-top: 20px;
  .button-area {
    margin-top: 18px;
  }
  @media (max-width: 767px) {
    .button-area {
      margin-top: 15px;
    }
  }
  
`;

export default HomeSliderDesiresWrapper;
