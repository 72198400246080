import React, { useEffect, useContext, useState, useRef } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
// import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';

import SuperWhispererHomeCardWrapper from 'ToolboxComponents/commons/cards/super-whisperer-home-card/super-whisperer-home-card.styles';
import Image from 'ToolboxComponents/commons/cards/super-whisperer-home-card/components/image/image';
import Secrets from 'ToolboxComponents/commons/cards/super-whisperer-home-card/components/secrets/secrets';
import Name from 'ToolboxComponents/commons/cards/super-whisperer-home-card/components/name/name';
import Life from 'ToolboxComponents/commons/cards/super-whisperer-home-card/components/life/life';
import Expert from 'ToolboxComponents/commons/cards/super-whisperer-home-card/components/expert/expert';
import KeepInTouch from 'ToolboxComponents/commons/cards/super-whisperer-home-card/components/keep-in-touch/keep-in-touch';
import slugify from 'url-slug';

const SuperWhispererHomeCard = props => {
  const detailsRef = useRef();
  const theme = useContext(ThemeContext).state;

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  return (
    <Anchor code='product' pageParams={`/1/${slugify('super secret')}`}>
      <SuperWhispererHomeCardWrapper
        className={`super-whisperer-home-card`}
        theme={theme}
      >
        {isMobile === true && (
          <>
            <div className={`user`} ref={detailsRef}>
              <div className={`header`}>
                <Image />
                <Secrets />
              </div>
              <Name />
            </div>
            <div className={`info`}>
              <Life sliderIndex={props.sliderIndex} />
              <Expert detailsRef={detailsRef} />
            </div>
            <div className={`link`}>
              <KeepInTouch />
            </div>
          </>
        )}
        {isMobile === false && (
          <>
            <Image />
            <div className={`details`}>
              <Name />
              <Life sliderIndex={props.sliderIndex} />
              <Expert />
              <div className={`more-info`}>En savoir plus...</div>
            </div>
            <div className={`actions`}>
              <KeepInTouch />
              <Secrets />
            </div>
          </>
        )}
      </SuperWhispererHomeCardWrapper>
    </Anchor>
  );
};

SuperWhispererHomeCard.propTypes = {};

export default SuperWhispererHomeCard;
