import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const SuperWhispererHomeCardWrapper = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  .user {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;

    .header {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      padding: 0 20px;
    }

    .image {
      background: ${props => props.theme.color.tertiaryColor};
      border-radius: 50%;
      border: 8px solid ${props => props.theme.color.tertiaryColor};
      overflow: hidden;
      min-width: 120px;
      max-width: 180px;
      position: relative;
      z-index: 1;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .secrets {
      background: ${props => props.theme.color.tertiaryColor};
      padding: 10px;
      border-radius: 8px;
      display: flex;
      flex-flow: wrap;
      flex: 0 0 150px;
      justify-content: flex-end;
      height: 90px;
      align-self: center;
      position: relative;
      left: -30px;

      .nb-secrets,
      .secret-text {
        height: 30px;
      }
    }
    .name {
      font-size: ${props => `${30*props.theme.font.secondaryFont.scaling/100}px`};
      font-family: ${props => props.theme.font.secondaryFont.name};
      font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
      text-align: center;
    }
  }

  .nb-secrets {
    font-family: ${props => props.theme.font.secondaryFont.name};
    font-size: ${props => `${35*props.theme.font.secondaryFont.scaling/100}px`};
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    color: ${props => props.theme.color.primaryColor};
  }

  .secret-text {
    font-size: ${props => `${17*props.theme.font.secondaryFont.scaling/100}px`};
    font-family: ${props => props.theme.font.secondaryFont.name};
    width: 72px;
    margin-left: 10px;
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    line-height: 18px;
    margin-top: 9px;
  }

  .secret-see-more {
    width: 100%;
    text-align: right;
    color: ${props => props.theme.color.primaryColor};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .info {
    margin-bottom: 55px;

    .life-title,
    .expert-title {
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      color: ${props => props.theme.color.primaryColor};
      margin-bottom: 5px;
      padding: 0 40px;
    }
  }

  .life {
    .life-description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 0 40px;
      margin-bottom: 10px;

      &.text-full {
        display: block;
      }
    }
    .life-read-more {
      float: right;
      height: 23px;
      background: linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, white 20%);
      position: relative;
      top: -35px;
      width: 86px;
      text-align: right;
      right: 40px;

      &:hover {
        pointer: cursor;
      }
      &.display-none {
        display: none;
      }
    }
  }
  .expert {
    // .expert-tags {
    //   overflow-y: auto;
    //   &::-webkit-scrollbar {
    //     display: none;
    //   }
    //   -ms-overflow-style: none;
    //   scrollbar-width: none;
    // }

    .expert-tag {
      background: #3e3e3e;
      padding: 2px 12px;
      border-radius: 10px;
      color: white;
      white-space: nowrap;
    }
  }

  .link {
    display: flex;
    justify-content: center;
    background: ${props => props.theme.color.tertiaryColor};
    border-radius: 8px;
    height: 71px;
  }

  .keep-in-touch {
    position: relative;
    top: -28px;
    width: 90px;
    height: 90px;
    color: white;
    background: #3e3e3e;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    border-radius: 50%;
    padding: 17px 13px 13px;
    text-align: center;
    transition: transform 0.1s;
    user-select: none;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  .keep-in-touch-icon {
    font-size: 40px;
    line-height: 21px;
  }

  .keep-in-touch-text {
    line-height: 16px;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    display: flex;
    padding-left: 20px;

    .image {
      background: ${props => props.theme.color.tertiaryColor};
      border-radius: 8px;
      height: 220px;
      width: 220px;
      flex: 0 0 auto;
      margin: 20px 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .details {
      margin-left: 30px;
      padding: 20px 0;

      .name {
        font-family: ${props => props.theme.font.secondaryFont.name};
        font-size: ${props => `${30*props.theme.font.secondaryFont.scaling/100}px`};
        font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
        text-transform: uppercase;
      }

      .life {
        .life-title {
          color: ${props => props.theme.color.primaryColor};
          font-size: ${props => `${15*props.theme.font.primaryFont.scaling/100}px`};
          line-height: 15px;
        }
        .life-description {
          padding: 0;
          padding-right: 40px;
        }
      }

      .expert {
        .expert-title {
          color: ${props => props.theme.color.primaryColor};
          font-size: ${props => `${15*props.theme.font.primaryFont.scaling/100}px`};
        }
        .expert-tag:first-child {
          margin-left: 0;
        }
      }

      .more-info {
        margin-top: 7px;
        color: ${props => props.theme.color.primaryColor};
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .actions {
      display: flex;
      flex-flow: column;
      flex: 0 0 200px;
      justify-content: space-around;
      align-items: center;
      background: ${props => props.theme.color.tertiaryColor};
      border-radius: 8px;

      .keep-in-touch {
        top: 0;
      }
      .secrets {
        text-align: center;

        .nb-secrets {
          font-size: ${props => `${40*props.theme.font.secondaryFont.scaling/100}px`};
          line-height: 42px;
        }
        .secret-text {
          text-transform: uppercase;
          margin: 0;
          width: 150px;
        }
        .secret-see-more {
          text-align: center;
        }
      }
    }
  }
`;

export default SuperWhispererHomeCardWrapper;
