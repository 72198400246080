import React from 'react';
// import PropTypes from "prop-types";
import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';

const ExpertWrapper = styled.div`
  .swiper-container {
    padding: 0 20px;
  }
  .swiper-slide {
    flex: 0;
  }
  .swiper-slide + .swiper-slide {
    margin-left: 5px;
  }
  .swiper-slide:first-child {
    margin-left: 20px;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .swiper-container {
      padding: 0 20px 0 0;
    }
    .swiper-slide:first-child {
      margin-left: 0;
    }
  }
`;

const Expert = props => {
  return (
    <ExpertWrapper className={`expert`}>
      <div className={`expert-title`}>Son expertise</div>
      <Slider
        className={`expert-tags`}
        mobile={{
          slidesPerView: 'auto',
          nested: true,
          freeMode: true,
        }}
        desktop={{
          slidesPerView: 'auto',
          nested: true,
          freeMode: true,
        }}
      >
        <span className={`expert-tag`}>boire un verre</span>
        <span className={`expert-tag`}>plage</span>
        <span className={`expert-tag`}>skate</span>
        <span className={`expert-tag`}>spa</span>
      </Slider>
    </ExpertWrapper>
  );
};

Expert.propTypes = {};

export default Expert;
