import React, { useContext } from 'react';
import { ThemeContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { Link } from 'react-router-dom';

import { CityCardWrapper } from "ToolboxComponents/commons/cards/city-card/city-card.styles";
// import PropTypes from 'prop-types';
import slugify from 'url-slug';

const CityCard = props => {
  const { city } = props;
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();

  return (
      <CityCardWrapper theme={theme} >
          <a className="city-card-link container "  style={{textDecoration: "none !important"}} href= {`${language === 'fr' ? 'https://www.saint-etienne-hors-cadre.fr/sorganiser/city-card-passtouristique/acheter-city-card/' : 'https://www.saint-etienne-hors-cadre.fr/en/get-organised/city-card-tourist-pass/buy-city-card/'}`} >
          <div className="row justify-content-center ">
            <div  className="row city-card-block justify-content-center ">
            <div className='col-4 city-card-photo'>
              <img src="https://api.agitateurs.com/upload/72eeb2e0-60c2-11eb-974d-5945c99ae888.png" alt="" className="img-fluid"/>
            </div>
            <div className=" col-8 city-card-text">
              {/*<span className="city-card">
                <Text path={`specific.cityCard.title`} />
              </span>*/}
              <p className="mb-0">
                <Text path={`specific.cityCard.description`} />
              </p>
              </div>
            </div>
            <div className='city-card-button justify-content-right'>{{
                        fr: <span>découvrir la City Card</span>,
                        en: <span>discover the City Card</span>
                      }[language]}</div>
         </div>
         </a>

      </CityCardWrapper>
  );
};

CityCard.propTypes = {};

export default CityCard;
