import React, { useContext } from 'react';
import { ThemeContext, WebappContext } from 'ToolboxUtils/web/context/context';
// import PropTypes from 'prop-types';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import styled from 'styled-components';
import SliderSecretProduct from 'ToolboxComponents/commons/sliders/slider-secret-product/slider-secret-product';

const HomeSliderSecretsWrapper = styled.div`
  .slider-secret-product {
    padding-left: 0;
    padding-right: 0;
  }

  .swiper-slide {
    padding: 0 15px;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .slider-secret-product {
      background: ${props => props.tertiaryColor};
      padding: 40px 20px;
    }
    .swiper-slide {
      padding: 0;
      display: flex;
    }
    .secret-product-card {
      background: white;
      flex: 2;

      .slide-container {
        background: white;
        grid-template-columns: 30px 1fr;
        padding: 30px;
        .fade-out-top {
          background: linear-gradient(
            180deg,
            rgb(255, 255, 255),
            rgba(255, 255, 255, 0)
          );
        }
        .fade-out-bottom {
          background: linear-gradient(
            0deg,
            rgb(255, 255, 255),
            rgba(255, 255, 255, 0)
          );
        }
      }
    }
  }
`;

const HomeSliderSecrets = props => {
  const { secrets } = props;
  const { tertiaryColor } = useContext(ThemeContext).state.color;
  const [analytics] = useContext(WebappContext).usePath('analytics');

  return (
    <HomeSliderSecretsWrapper
      className={`home-slider-secrets`}
      tertiaryColor={tertiaryColor}
    >
      <SliderSecretProduct
        onClick={() => {analytics.sendSecretsInteractionsHomepageEvent()}}
        secrets={secrets}
        homeProductCards={[]}
      ></SliderSecretProduct>
    </HomeSliderSecretsWrapper>
  );
};

HomeSliderSecrets.propTypes = {};

export default HomeSliderSecrets;
