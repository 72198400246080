import React, { useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
// import PropTypes from 'prop-types';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import { MomentsHomeCardWrapper } from './moment-home-card.styles';

const MomentsHomeCard = props => {
  const { moment } = props;
  const theme = useContext(ThemeContext).state;
  return (
    <MomentsHomeCardWrapper theme={theme}>
      <Anchor className={`card-link`} code='circuits' pageParams={`/${moment.id}`}>
        <div className={`moments-home-card-image`}>
          <ImageResize
            src={moment.photo}
          />
        </div>
        <div className={`moments-home-card-title line-clamp-1`}>
          {moment.title}
        </div>
        <div className={`moments-home-card-description line-clamp-2`}>
          {moment.teaser}
        </div>
        <div className={`moments-home-card-nb-steps`}>
          {moment.stopCount} étape{moment.stopCount > 1 ? 's' : ''}
        </div>
      </Anchor>
    </MomentsHomeCardWrapper>
  );
};

MomentsHomeCard.propTypes = {};

export default MomentsHomeCard;
