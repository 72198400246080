import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const innerHeight = window.innerHeight;

const PrehomeSliderWrapper = styled.div`
display: flex;
flex-flow: column;
align-item: center;
width: calc(100vw - ${props => props.scrollbarWidth / 2}px);


.arrow {
  display: none;
}

.swiper-button {
  background:none;
  box-shadow: none;
} 

.swiper-button svg {
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}

.swiper-slide .content {
  transform: scale(0.5);
}

.see-more {
  font-size: 14px;
  font-weight: bold;
  color:  ${props => props.theme.color.primaryColor};
 }

justify-content: flex-end;
  .content {
    width: 100%;
    display: flex;

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }

  .prehome {
    z-index:5;
    width: 100%;
    background: #ffffff;
    height: ${innerHeight - 60 - 62}px;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: flex-end;
    box-shadow: 0 5px 36px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 40px;
  }

  .subtitle-dekstop {
    display: none;
  }

  .subtitle-mobile {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    padding: 12px 12px 3px 18px;
    text-align: center;
    .title-mobile {
      color:  ${props => props.theme.color.primaryColor};
      font-family: 'Montserrat', sans-serif;
      .secret-count {
        font-weight: 600;
      }
      .text {
        font-size: 34px;
        font-weight: 500;
      }
    }
    .subtitle-text {
      margin-top: -8px;
      line-height:20px;
    }
  }

  #slider {
    min-height: 0;
  }

  .prehome-hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #prehome-banner-content {
    position: relative;
  }

  .prehome-banner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 10px 10px;
    margin-bottom: ${props => props.hasStarWhisperers ? '0' : '20px'};

    .title-desktop {
      display: none;
    }

    .users {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: space-between;

      .user {
        width: 70px;
        .image {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          border: 3px solid #ffffff;
          overflow: hidden;
          box-shadow: 0 3px 6px rgba(17, 17, 17, 0.4);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .name {
          text-align: center;
          font-size: 11px;
          margin-top: 3px;
          white-space: nowrap;
          color: #3a3e42;
        }
        .name.superWhisperers {
          margin-left: -12px;
        }
      }
      .whisperer-badge {
        margin-left: 10px;

        .user-count {
          font-size: 12.5px;
          position: absolute;
          left: -22px;
          bottom: 0;
        }

        .badge {
          img {
            width: 80px;
            height: 80px;
            transform: rotate(20deg);
            border-radius: 50%;
            box-shadow: 0 3px 6px rgba(17, 17, 17, 0.4);
          }
        }
      }

      .secret {
        min-height: 0;
        display: flex;
        flex-direction: column;
        jutify-content: space-between;
        color: white;
        font-size: 14x;
        background-color: #3a3e42;
        position: absolute;
        bottom: 100px;
        left: 15px;
        right: 15px;
        z-index: 50;
        min-height: 100px;
        max-height: 500px;
        border-radius: 20px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.6s, opacity 0.6s linear;
        padding: 12px 16px;
        .secret-title {
          position: relative;
          z-index: 8;
          font-weight: bold;
          font-size: 19px;
          line-height: 22px;
          margin-bottom: 4px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .secret-description {
          min-height: 0;
          z-index: 8;
          flex-grow: 1;
          flex-shrink: 1;
          position: relative;
          margin-bottom: 4px;
          line-height: 17px;
          overflow-y: auto;
          padding: 3px 0px;
        }
        .secret-author {
          position: relative;
          z-index: 8;
          padding-right: 6px;
          width: 100%;
          color:  ${props => props.theme.color.primaryColor};
          text-align: right;
          white-space: nowrap;
        }
        .secret-tail {
          z-index: 7;
          position: absolute;
          height : 0;
          width : 0;
          bottom: -33px;
          border-top : 50px solid #3a3e42;
          border-right : 25px solid transparent;
          border-left : 25px solid transparent;
          transform: rotate(25deg);
        }
      }
      .visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 2s linear 1.2s;
      }
    }
  }

  @media (min-width: 992px) {
    .prehome-hero-image {
      margin-left: 12px;
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {

    .prehome {
      z-index: 1;
      width: calc(100vw - ${props => props.scrollbarWidth / 2}px);
      height: calc(100vh - 190px);
      margin-bottom: ${props => props.hasStarWhisperers ? '0' : '20px'};
    }

    .prehome-hero-image {
      margin-left: 0;
    }

    .subtitle-mobile {
      display:none;
    }

    .arrow {
      display: block;
      position:absolute;
      top: calc((-100vh + 188px + 80px) / 2 - 35px - 18px);
      cursor: pointer;
    }
    
    .arrow::after {
      color: white;
      opacity: 70%;
      filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
      font-family: swiper-icons;
      font-size: 70px;
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
    }
    
    .arrow.left-arrow {
      left : 20px;
    }
    
    .arrow.right-arrow {
      right : 20px;
    }
      
    .arrow.left-arrow::after {
      content: 'previous';
    }
    
    .arrow.right-arrow::after {
      content: 'next';
    }

    .prehome-banner {
      padding: 18px 10px 10px

      .title-desktop {
        display:flex;
        flex-direction:column;
        color:  ${props => props.theme.color.primaryColor};
        .secret-count {
          font-family: 'Verdana', sans-serif;        
          font-weight: 700;
          font-size: 90px;
          line-height: 55px;
        }
        .text {
          font-weight: 500;
          letter-spacing: -1.5px;
          padding-right: 8px;
          font-size: 55px;
          line-height: 42px;
        }
      }

      .users {
        width: 100%;
        height: 160px;
        display: flex;
        justify-content: space-between;
  
        .user {
          width: 120px;
          .image {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            border: 3px solid #ffffff;
            overflow: hidden;
            box-shadow: 0 3px 6px rgba(17, 17, 17, 0.4);
  
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .name {
            text-align: center;
            font-size: 15px;
            font-weight: bold;
            margin-top: 5px;
          }
        }
        .whisperer-badge {
          position: relative;
          margin-left: 10px;
  
          .user-count {
            font-size: 12.5px;
            position: absolute;
            left: -22px;
            bottom: 0;
          }
  
          .badge {
            img {
              width: 80px;
              height: 80px;
              transform: rotate(20deg);
              border-radius: 50%;
              box-shadow: 0 3px 6px rgba(17, 17, 17, 0.4);
            }
          }
        }
        .whisperers {
          position: relative;
        }
        .secret {
          display: flex;
          flex-direction: column;
          jutify-content: space-between;
          min-height: 0;
          color: white;
          font-size: 18px;
          background-color: #3a3e42;
          position: absolute;
          bottom: 200px;
          left: -15px;
          z-index: 50;
          width: 450px;
          min-height: 100px;
          max-height: 500px;
          border-radius: 20px;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s 0.6s, opacity 0.6s linear;
          padding: 26px;
          .secret-title {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 10px;
            white-space: none;
            text-overflow: none;
            overflow: none;
          }
          .secret-description {
            min-height: 0;
            margin-bottom: 10px;
            line-height: 22px;
          }
          .secret-author {
            padding-right: 6px;
            width: 100%;
            color:  ${props => props.theme.color.primaryColor};
            text-align: right;
          }
          .secret-tail {
            transform: rotate(0deg);
            bottom: -40px;
            position: absolute;
            left: 40px;
            height : 0;
            width : 0;
            border-top : 70px solid #3a3e42;
            border-right : 35px solid transparent;
            border-left : 35px solid transparent;
          }
        }
        .visible {
          visibility: visible;
          opacity: 1;
          transition: opacity 2s linear 1.2s;
        }
        .position2 {
          left: -165px;
          .secret-tail {
            left: 190px;
          }
        }
        .position3, .position4 {
          left: -315px;
          .secret-tail {
            left: 340px;
          }
        }
      }
    }
  }

`;

export default PrehomeSliderWrapper;
