import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const HomeSliderProductWrapper = styled.div`
padding-top: 20px;

.block-title {
  margin-bottom: 4px !important;
}

@media (max-width: 767px) {
  .button-area {
    margin-top: -10px;
  }
}

.swiper-slide {
  padding: 0 15px;
}

.swiper-pagination-design {
  top: 0;
}
@media (min-width: ${isMobileBreakpoint}px) {
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;

  .swiper-slide {
    padding: 0;
  }
}
  
.logo {
  display: flex;
  justify-content: center;
  align-items: center;    
  background-color: white;
  width: 520px;
  > span {
    width: fit-content!important;
  }
  > img {
    width: 80px;
    margin-bottom: 4px;
    margin-left: 10px;
  }
}

`;

export default HomeSliderProductWrapper;
