import styled from 'styled-components';

export const TrustHomeCardWrapper = styled.div`
  background: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 20px 40px;

  .trust-home-image {
    height: 140px;
    width: 140px;
    font-size: 100px;
    background: ${props => props.theme.color.tertiaryColor};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3e3e3e;
  }

  .trust-home-title {
    font-size: ${props =>
      `${(20 * props.theme.font.primaryFont.scaling) / 100}px`};
    font-weight: ${props =>
      parseInt(props.theme.font.primaryFont.weight) + 300};
    text-align: center;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  span {
    color: ${props => props.theme.color.primaryColor};
  }

  .trust-home-subtitle {
    text-align: center;
    span {
      color: #3e3e3e;
    }
  }
`;
