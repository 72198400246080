import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Weather from 'ToolboxComponents/commons/weather/weather';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import styled from 'styled-components';

const HomeTitleWrapper = styled.div`
  .title-block {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 0;

    @media (min-width: ${isMobileBreakpoint}px) {
      padding: 40px 20px 0;
    }
  }
  .home-title {
    max-width: 840px;
  }
  .weather {
    display: none;
    @media (min-width: ${isMobileBreakpoint}px) {
      display: flex;
    }
  }
  .title {
    font-size: ${props => `${19 * props.theme.font.primaryFont.scaling / 100}px`};
  }

  strong {
    color: ${props => props.theme.color.primaryColor};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight) + 300};
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .title {
      font-size: ${props => `${40 * props.theme.font.primaryFont.scaling / 100}px`};
    }
  }
`;

const HomeTitleWeather = props => {
  const theme = useContext(ThemeContext).state;

  return (
    <div className={`title-block`}>
      <HomeTitleWrapper className={`home-title`} theme={theme}>
        <h2 className={`title`}>
          <Text path={`page.homepage.title`} />
        </h2>
      </HomeTitleWrapper>
      <Weather />
    </div>
  );
};

HomeTitleWeather.propTypes = {};

export default HomeTitleWeather;
