import React, { useContext } from 'react';
import { ThemeContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { HeartCardWrapper } from "ToolboxComponents/commons/cards/heart-card/heart-card.styles";
// import PropTypes from 'prop-types';
import slugify from 'url-slug';

const CircuitCard = props => {
  const { circuit } = props;
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();

  
  return (
    <Anchor code={circuit.button.link.page} onClick={() => {props.onClick && props.onClick()}} pageParams={circuit.button.link.pageParams}>
      <HeartCardWrapper theme={theme}>
       <div className="home-circuits">
                <div className="home-circuits-inner">
                   <ImageResize className="home-circuits-photo home-circuits-photo2" src={circuit.photo} />
                  <div className="home-circuits-text">
                    <div className="home-circuits-title">
                      {circuit.title}
                    </div>
                    <div className="home-circuits-description">
                      {circuit.teaser}
                    </div>
                  </div>
                </div>

                <div className="home-circuits-button-area">
                    <div className="home-circuits-button">
                      <span className="home-circuits-button-text">
                        <Text path={circuit.button.text} data={circuit.button.data}/>
                      </span>
                    </div>
                </div>
        </div>
   
      </HeartCardWrapper>
    </Anchor>
  );
};

CircuitCard.propTypes = {};

export default CircuitCard;
