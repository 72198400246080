import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { TrustHomeCardWrapper } from "ToolboxComponents/commons/cards/trust-home-card/trust-home-card.styles";

const TrustHomeCard = props => {
  const { name, icon } = props;
  const theme = useContext(ThemeContext).state;

  return (
    <TrustHomeCardWrapper theme={theme}>
      <div className={`trust-home-image adn adn-${icon}`}></div>
      <div className={`trust-home-title`}>
        <Text path={`${name}Title`} />
      </div>
      <div className={`trust-home-subtitle`}>
        <Text path={`${name}Content`} />
      </div>
    </TrustHomeCardWrapper>
  );
};

TrustHomeCard.propTypes = {};

export default TrustHomeCard;
