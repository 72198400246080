import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const HeartstoppersWrapper = styled.div`
  padding-top: 20px;
  

  .swiper-pagination-design {
    top: 20px;
    margin-bottom: 30px;
  }

  .swiper-pagination-bullet {
    background: ${props => props.tertiaryColor};
  }

  .circuit-title {
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    font-size: 21px;
    text-align: center;
    line-height: 1.5;
  }

  .circuit-main {
    display: block;
    font-family: ${props => props.theme.font.secondaryFont.name};
    color: ${props => props.primaryColor};
    text-transform: uppercase;
    font-size: ${props => `${18*props.theme.font.secondaryFont.scaling/100}px`};
  }

  .circuit-sub {
    display: block;
    color: ${props => props.primaryColor};
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 767px) {
    .circuit-sub {
      margin: 0;
    }
  }

`;

export default HeartstoppersWrapper;
