import React, { useContext } from 'react';
import { ThemeContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import formatSchedule from 'ToolboxUtils/web/formats/format-schedule';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { UpcomingEventsHomeCardWrapper } from "ToolboxComponents/commons/cards/upcoming-events-home-card/upcoming-events-home-card.styles";
// import PropTypes from 'prop-types';
import slugify from 'url-slug';

const UpcomingEventsHomeCard = props => {
  const { upcomingEvent } = props;
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();

  const schedule = formatSchedule(
    true, {
    startTime: upcomingEvent.startTime,
    endTime: upcomingEvent.endTime,
    language,
  });
  return (
    <Anchor code='product' onClick={() => {props.onClick && props.onClick()}} pageParams={`/${upcomingEvent.id}/${slugify(upcomingEvent.title, {lower: true})}`}>
      <UpcomingEventsHomeCardWrapper theme={theme}>
        <div className={`upcoming-events-home-card-image`}>
          <ImageResize src={upcomingEvent.photo} />
        </div>
        <div className={`upcoming-events-home-card-title line-clamp-2`}>
          {upcomingEvent.title}
        </div>
        <div className={`upcoming-events-home-card-city line-clamp-1`}>
          {upcomingEvent.city}
        </div>
        <div className={`upcoming-events-home-card-date`}>
          <Text
            path={schedule.message}
            data={{ time: schedule.hour, date: schedule.date }}
            className={`is-${schedule.status}`}
          />
        </div>
      </UpcomingEventsHomeCardWrapper>
    </Anchor>
  );
};

UpcomingEventsHomeCard.propTypes = {};

export default UpcomingEventsHomeCard;
