import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import styled from 'styled-components';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import TrustHomeCard from 'ToolboxComponents/commons/cards/trust-home-card/trust-home-card';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import { text } from '@storybook/addon-knobs';

const HomePrestaWrapper = styled.div`
  padding: 20px 0px 10px 0px;
  .home-presta-block {
    margin: 0 auto;
    max-width: 820px;
    padding: 0 20px;
    img {
      max-width: 100%;
    }
  }
`;

const HomePresta = props => {
  const theme = useContext(ThemeContext).state;
  const { primaryColor, tertiaryColor } = useContext(ThemeContext).state.color;
  // const [activeProducts, setActiveProducts] = useState(products);

  return (
    <HomePrestaWrapper
      className={`home-slider-trust`}
      primaryColor={primaryColor}
      tertiaryColor={tertiaryColor}
      theme={theme}
    >
      <div className={`home-presta-block`} dangerouslySetInnerHTML={{ __html: props.text }} />
    </HomePrestaWrapper>
  );
};

HomePresta.propTypes = {};

export default HomePresta;
