import React, { useContext, useState } from 'react';
// import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import styled from 'styled-components';
import { ThemeContext, WebappContext } from 'ToolboxUtils/web/context/context';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import HeartCard from 'ToolboxComponents/commons/cards/heart-card/heart-card';
import HeartstoppersWrapper from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-heartstoppers/home-slider-heartstoppers.style';



const Heartstoppers = props => {
  const theme = useContext(ThemeContext).state;
  const [analytics] = useContext(WebappContext).usePath('analytics');

  const [isMobile, setIsMobile] = useState(isMobileView());
  const { products } = props;
  const { primaryColor, tertiaryColor } = useContext(ThemeContext).state.color;

  return (
    <HeartstoppersWrapper
      className={`home-slider-circuits circuit`}
      primaryColor={primaryColor}
      tertiaryColor={tertiaryColor}
      theme={theme}
    >
      <div className={`block-title sub`}>
        <hr></hr>
        <div className={`title`}>
          <span>{props.title}</span>
        </div>
        <div className={`sub-title`}>
          <Text path={`page.homepage.circuitsSubtitle`} />
        </div>
      </div>
      {isMobile ?
      <>
      <Slider
        mobile={{
          spaceBetween: 20,
          slidesPerView: 1.1,
          slidesPadding: 12,
        }}
        desktop={{
          slidesPerView: 3,
          spaceBetween: 50,
          centerInsufficientSlides: true,
        }}
        dotsColorActive={primaryColor}
      >
        {products.filter(x => !!x).map((circuit, index) => (
          <HeartCard
            onClick={() => {analytics.sendVireesInteractionsHomepageEvent()}}
            key={`circuit-card--${index}`}
            circuit={circuit}
          />
        ))}
      </Slider>
      </>
      :
        <>
         <Slider
            mobile={{
              spaceBetween: 20,
              slidesPerView: 1.1,
              slidesPadding: 12,
            }}
            desktop={{
              slidesPerView: 3,
              spaceBetween: 50,
              centerInsufficientSlides: true,
            }}
            dotsColorActive={primaryColor}
          >
            {products.map((circuit, index) => (
              <HeartCard
                onClick={() => {analytics.sendVireesInteractionsHomepageEvent()}}
                key={`circuit-card--${index}`}
                circuit={circuit}
              />
            ))}
          </Slider>
        </> 
      }
    </HeartstoppersWrapper>
  );
};

Heartstoppers.propTypes = {};

export default Heartstoppers;
