import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { WebappContext, ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import styled from 'styled-components';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import ProductCard from 'ToolboxComponents/commons/cards/product-card/product-card';
import HomeSliderProductWrapper from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-product/home-slider-product.style';
import slugify from 'url-slug';

const HomeSliderProduct = props => {
  const theme = useContext(ThemeContext).state;
  const { products, sliderTitle, addFavorite, deleteFavorite,desireName } = props;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const [resultsView, setResultsView] = useContext(WebappContext).usePath('resultsView');
  const [search, setSearch] = useContext(WebappContext).usePath('search');

  const resetSearch = () => {
    setSearch(null);
    setResultsView({
      selectedSlideId: null,
      activeProducts: null,
    })
  }

  // const [activeProducts, setActiveProducts] = useState(products);
  const toggleFavoriteActive = (active, productId) => {
    if (active === true) {
      deleteFavorite(productId);
    } else {
      addFavorite(productId);
    }
  };
  

  return (
    <HomeSliderProductWrapper className={`home-slider-product`} theme={theme}>
      <div className={`block-title`}>
        <hr></hr>
        <div className={`title alone`}>
        {  (whiteLabel.id === 10 || whiteLabel.id === 24)  ?
          <div className={`logo`}>
            <Text path={sliderTitle} />
            <img src={whiteLabel.pictures.logo} alt='logo' />
          </div>
          :
          <Text path={sliderTitle} />
        }
        </div>
      </div>
      <Slider
        mobile={{
          dots: true,
        }}
        desktop={{
          slidesPerView: 3,
          arrows: false,
          spaceBetween: 40,
          slidesPadding: 20,
          centerInsufficientSlides: true,
        }}
      >
        {products !== undefined &&
          products.map((product, index) => (
            <ProductCard
              key={`slide-product--${index}`}
              id={`slide-product--${index}`}
              product={product}
              toggleFavoriteActive={toggleFavoriteActive}
              fromPage={'home'}
              onClick={() => { analytics.sendProduitsInteractionsHomepageEvent() }}
            />
          ))}
      </Slider>
      <div className="button-area">
        <Anchor code='catalog' pageParams={`/envie/${slugify(desireName, {lower: true})}`} onClick={() => { analytics.sendVoirEnviesInteractionsHomepageEvent();  }}>
          <div className="button">
            <span className="button-text">
              <Text path={`page.homepage.productsSliderButton`} />
            </span>
          </div>
        </Anchor>
      </div>
      
    </HomeSliderProductWrapper>
  );
};

HomeSliderProduct.propTypes = {
  products: PropTypes.array,
  sliderTitle: PropTypes.string,
  desireName:PropTypes.string
};

export default HomeSliderProduct;
