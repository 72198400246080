import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import styled from 'styled-components';
import { ThemeContext, WhiteLabelContext, WebappContext } from "ToolboxUtils/web/context/context";
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import photoDestination from 'ToolboxAssets/img/bloc-destination.jpg';


const HomeDestinationWrapper = styled.div`
  margin-top: 100px;
  .discover {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    color: #fff;
    width: calc(100vw - 0.7%);
    margin-left: calc(-50vw + 50%);
    box-sizing: border-box;
  }

.discover-text {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.color.primaryColor};
  width: 50%;
  padding: 50px 90px;
}

.discover-text>p {
  box-sizing:  content-box;
  color: #fff;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.7px;
  margin: 0;
}

.discover-text h2 {
  color: #fff;
  font-family: ${props => props.theme.font.primaryFont.name};
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
  font-weight: 600;
  margin-bottom: 40px;
}


.discover-photo {
  width: 50%;
}

.discover-photo .img-fluid {
  height: 100%;
  object-fit: cover;
}

.discover-photo img {
  height: auto;
}

.discover-button-area {
  margin-top: 30px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.discover-button {
  display: flex;
  flex-direction: row;
  background-color: white;
  color: ${props => props.theme.color.primaryColor};
  padding: 4px 11px 4px 9px;
  border-radius: 15px;
  align-items: center;
}

.discover-button-plus {
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 20px;
  padding:0px 1px 0px 2px;
  width:20px;
  height:20px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color.primaryColor};
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .discover {
    margin-top: 40px;
    flex-direction: column-reverse;
  }

  .discover-text {
    width: auto;
    padding: 18px 24px;
    h2 {
      font-size: 20px;
    }
  }

  .discover-text p {
    margin-top: 18px;
    font-size: 14px;
    line-height: 18px;
    line-height: auto;
    letter-spacing: auto;
  }

  .discover-photo {
    height: 256px;
    width: auto;
  }

  .discover-button-area {
    margin-top: 16px;
  }
}
`;

const HomeDestination = props => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const { primaryColor, tertiaryColor } = useContext(ThemeContext).state.color;
  // const [activeProducts, setActiveProducts] = useState(products);

  return (
    <HomeDestinationWrapper
      className={`home-slider-destination`}
      primaryColor={primaryColor}
      tertiaryColor={tertiaryColor}
      theme={theme}
    >
      <section className="discover">
        <div className="discover-text">
          <div>
          <h2>                
            <Text path='page.homepage.destinationTitle' />  
          </h2>
          <p>
            <Text path='page.homepage.destinationText' />  
          </p>
          </div>
          <div className="discover-button-area">
            <div className="discover-button">
              <div className="discover-button-plus"> + </div>
              <Anchor code="destination" className="discover-button-link" onClick={() => {analytics.sendDestinationInteractionsHomepageEvent()}}>
                <Text path='page.homepage.destinationButton' />  
              </Anchor> 
            </div>
          </div>
        </div>
        <div className="discover-photo">
          <img src={photoDestination} alt="" className="img-fluid resize" />
        </div>
      </section>
    </HomeDestinationWrapper>
  );
};

HomeDestination.propTypes = {};

export default HomeDestination;
