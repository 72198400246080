import React, { useContext, useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext, WebappContext, WhiteLabelContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import slugify from 'url-slug';
import HomeSliderEventsWrapper from './home-slider-events.style';
import formatSchedule from 'ToolboxUtils/web/formats/format-schedule';
import DistanceConverted from 'ToolboxComponents/utils/formats/distances/distance';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';


const HomeSliderEvents = props => {
  const { currentEvent, events } = props;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const theme = useContext(ThemeContext).state;
  const color = useContext(ThemeContext).state.color.primaryColor;

  return (
    <HomeSliderEventsWrapper
      theme={theme}
      color={color}
      currentEvent={currentEvent}
    >
      <div className={`sub`}>
        <div className={`title-event`}>
          <Text path={`page.homepage.eventsTitle`} />
        </div>
        <div className={`sub-title-event`}>
          <Text path={`page.homepage.eventsSubTitle`} />
        </div>
      </div>
      <div className={`block-events`}>
        <div className={`block-events-photo`}>
          <span>{whiteLabel.config && whiteLabel.config.displayEventName !==false && currentEvent.name}</span>
        </div>
        <div className={`block-events-content`}>
          <div className={`block-events-list`}>
          {events.slice(0, 3).map((product, index) => {
            const schedule = formatSchedule(
              true, {
              startTime: product.startTime,
              endTime: product.endTime,
              language,
            });
            return (
            <Anchor
              code="product"
              className={`event-card`}
              onClick={() => {analytics.sendEvenementInteractionsHomepageEvent()}}
              pageParams={`/${product.id}/${slugify(product.title, {lower: true})}`}
            > 
              <div className={`product-photo`}>
                <ImageResize src={product.photo} alt={product.title} />
              </div>           
              <div className={`product-info`}>
                <div className={`product-info-name`}>
                  {product.title}
                </div>
                <div className={`product-infobar`}>
                  <div className={`secrets`}>{product.secretCount}</div>
                  {whiteLabel.isInZone &&
                  <div className="distance">
                    <span className="adn adn-directions icon" />
                    <DistanceConverted distance={product.distance} />
                  </div>
                  }
                  <div className="schedule">
                    <span className={`adn adn-clock-outline icon`} />
                    {schedule.path !== null && (
                      <Text
                        path={schedule.message}
                        data={{ time: schedule.hour, date: schedule.date }}
                        className={`is-${schedule.status}`}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Anchor>)
            })}
          </div>
          <div className={`block-events-more`}>
            <Anchor onClick={() => {analytics.sendVoirEvenementInteractionsHomepageEvent()}} code='catalog' pageParams={`/envie/${slugify(currentEvent.name, {lower: true})}`}>
              <Text path={`page.homepage.eventsMore`} />
            </Anchor>
          </div>
        </div>
      </div>
    </HomeSliderEventsWrapper>
  );
};

HomeSliderEvents.propTypes = {};

export default HomeSliderEvents;
