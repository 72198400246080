import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const KeepInTouchWrapper = styled.div`
  background: red;
`;

const KeepInTouch = props => {
  const [isKeepInTouch, setIsKeepInTouch] = useState(false);

  return (
    <KeepInTouchWrapper
      onClick={() => setIsKeepInTouch(!isKeepInTouch)}
      className={`keep-in-touch`}
    >
      <div
        className={`keep-in-touch-icon adn ${
          isKeepInTouch === true ? 'adn-heart' : 'adn-heart-outline'
        }`}
      ></div>
      <div className={`keep-in-touch-text`}>Garder le lien</div>
    </KeepInTouchWrapper>
  );
};

KeepInTouch.propTypes = {};

export default KeepInTouch;
