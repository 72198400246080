import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import imageBifurcations from 'ToolboxAssets/img/Signes_bifurcations.png';

export default styled.div`
  padding: 30px 0 20px;
  position:relative;
  &:before{
    position:absolute;
    background: ${props => props.theme.color.primaryColor} url(${imageBifurcations});
    opacity: 80%;
    top:0;
    left:50%;
    bottom:0;
    display:block;
    content:'';
    width:100vw;
    transform: translateX(-50%);
    overflow: hidden;
    }

  .block-events-photo span {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size:40px;
    color: white;
    font-weight: bold;
    text-shadow: 1px 2px 8px rgb(17 17 17 / 40%);
  }

  .sub {
    position:relative;
    margin-bottom:24px;
    .title-event,.sub-title-event{
      text-align:center;
      span{
        background-color:unset;
        color:white;
        font-size:34px;
      }
      &.title-event{
        font-weight:bold;
      }
    }
  }
  

 .block-events {
    background-color: ${props => props.theme.color.primaryColor};
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position:relative;
  }

  .block-events-photo {
    width: 430px;
    height: 430px;
    background: url("${props => props.currentEvent.picture}") no-repeat;
    background-size: cover;
  }

  .block-events-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 430px);
    height: 430px;
  }

  .block-events-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 92%;
    padding: 0 30px 0 60px;
  }

  .event-card {
    display: flex;
    height: 28%;
    flex-basis: fit-content;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 16px 12px;
  }

  .product-photo {
    flex-shrink: 0;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    border: 2px solid #ffffff;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(17, 17, 17, 0.4);
    margin: 0 20px 0 8px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    font-size: 20px;
    overflow: hidden;
  }

  .product-info-name {
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

  .product-infobar {
    display: flex;
    flex-direction: row;   
    font-size: 16px;

    .distance,
    .schedule {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 5px;
        font-size: 24px;
        color: black;
      }
    }

    .distance {
      margin-right: 10px;
    }
  }

  .block-events-more {
    padding: 2px 32px 8px 0;
    margin: -4px;
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    text-align: right;
    vertical-align: center;
    a {
      color: white;
      text-decoration: underline;
    }
  }

  @media (max-width: ${isMobileBreakpoint - 1}px) {
    padding: 30px 0 0;

    .block-events {
      flex-direction: column;
    }

    .block-events-photo {
      width: 100vw;
      height: 100vw;
    }

    .block-events-content {
      width: 100%;
      height: auto;
    }

    .block-events-list {
      padding: 12px 12px 0 12px;
    }

    .event-card {
      height: auto;
      margin-bottom: 12px;
    }

    .product-photo {
      height: 70px;
      width: 70px;
      margin: 0 12px 0 0;
    }

    .product-info-name {
      font-size: 16px;
    }

    .product-infobar {
      .distance {
        display: none;
      }
    }

    .block-events-more {
      margin : 0;

      a {
        z-index: 1;
      }
    }
  }
`;
