import React, { useContext, useState } from 'react';
// import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import styled from 'styled-components';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import SuperWhispererHomeCard from 'ToolboxComponents/commons/cards/super-whisperer-home-card/super-whisperer-home-card';
import products from 'ToolboxComponents/commons/sliders/slider/fake-data/slides-product.js';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';

const HomeSliderSuperWhispererWrapper = styled.div`
  padding: 30px 0 20px;

  background: ${props => props.theme.color.primaryColor};
  .swiper-pagination-design {
    top: 20px;
    margin-bottom: 0;
  }

  .home-slider-super-whisperer-title {
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    text-align: center;
  }

  .home-slider-super-whisperer-main {
    font-family: ${props => props.theme.font.secondaryFont.name};
    text-transform: uppercase;
    font-size: ${props => `${20*props.theme.font.secondaryFont.scaling/100}px`};
    color: white;
    padding: 0 10px;
  }

  .home-slider-super-whisperer-sub {
    margin-bottom: 20px;
  }

  .home-slider-super-whisperer-more {
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    text-align: center;
    margin-top: 20px;

    a {
      color: black;
    }
  }

  .expert {
    .swiper-button {
      display: none;
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    padding: 30px 20px 20px;

    .swiper-pagination-design {
      top: 20px;
      margin-bottom: 0;
    }

    .home-slider-super-whisperer-more {
      text-align: right;
      position: relative;
      top: -22px;
      right: 80px;

      a {
        position: relative;
        z-index: 1;
      }
    }
  }
`;

const HomeSliderSuperWhisperer = props => {
  const theme = useContext(ThemeContext).state;
  const activeProducts = products;
  // const [activeProducts, setActiveProducts] = useState(products);
  const [sliderIndex, setSliderIndex] = useState(0);

  return (
    <HomeSliderSuperWhispererWrapper
      className={`home-slider-super-whisperer`}
      theme={theme}
    >
      <div className={`home-slider-super-whisperer-title`}>
        <div className={`home-slider-super-whisperer-main`}>
          <Text path={`page.homepage.superWhispererTitle`} />
        </div>
        <div className={`home-slider-super-whisperer-sub`}>
          <Text path={`page.homepage.superWhispererSubtitle`} />
        </div>
      </div>
      <Slider
        mobile={{
          slidesPerView: 1,
          spaceBetween: 30,
          slidesPadding: 20,
          dots: true,
        }}
        desktop={{
          slidesPerView: 1,
          spaceBetween: 30,
          slidesPadding: 20,
          dots: true,
          arrows: true,
          dotsInside: true,
          centerInsufficientSlides: true,
        }}
        dotsColor={`#ffffff`}
        getCurrentSlideIndex={flag => setSliderIndex(flag)}
      >
        {activeProducts.map((product, index) => (
          <SuperWhispererHomeCard
            key={`super-whisperer-home-card--${index}`}
            sliderIndex={sliderIndex}
          />
        ))}
      </Slider>
      <div className={`home-slider-super-whisperer-more`}>
        <Anchor code="home">
          <Text path={`page.homepage.superWhispererMore`} />
        </Anchor>
      </div>
    </HomeSliderSuperWhispererWrapper>
  );
};

HomeSliderSuperWhisperer.propTypes = {};

export default HomeSliderSuperWhisperer;
