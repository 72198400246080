import React, { useState, useEffect, useContext } from 'react';
import formatSchedule from 'ToolboxUtils/web/formats/format-schedule';
import { ThemeContext, LanguageContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import PropTypes from 'prop-types';
import { CardWrapper } from './product-card.style';
import businessHours from './assets/business-hours.svg';
import distance from './assets/distance.svg';
import Favorite from 'ToolboxComponents/commons/buttons/favorite/favorite';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import DistanceConverted from 'ToolboxComponents/utils/formats/distances/distance';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import slugify from 'url-slug';

const ProductCard = props => {
  const { className, id, ...rest } = props;
  const { product } = props;
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [scheduleStatus, setScheduleStatus] = useState('');
  const [schedulePath, setSchedulePath] = useState(null);
  const [scheduleHour, setScheduleHour] = useState({});
  const [scheduleDate, setScheduleDate] = useState({});


  useEffect(() => {
    const schedule = formatSchedule(
      props.isEvent, {
      startTime: product.startTime,
      endTime: product.endTime,
      language,
    });

    setScheduleStatus(schedule.status);
    setSchedulePath(schedule.message);
    setScheduleHour(schedule.hour);
    setScheduleDate(schedule.date);
    // eslint-disable-next-line
  }, [product]);
 

  return (
    <CardWrapper
      id={id}
      className={`product-card ${className}`}
      key={product.id}
      {...rest}
      theme={theme}
    >
      <div className="header">
        <Anchor
          code="product"
          onClick={() => {props.onClick && props.onClick()}}
          pageParams={`/${product.id}/${slugify(product.title, {lower: true})}`}
        >
          <ImageResize className="image" src={product.photo} alt={product.title} />
        </Anchor>
        <Favorite product={product} fromPage={props.fromPage} setFavorite={active => props.toggleFavoriteActive(active, product.id)} />
      </div>
      <Anchor
        code="product"
        pageParams={`/${product.id}/${slugify(product.title, {lower: true})}`}
      >
        <div className="content">
          <div className="title">{product.title}</div>
          <div className="city">{product.city}</div>
          {product.secretCount !== undefined && (
            <div
              className={`nb-secrets ${
                product.secretCount === 0 ? 'invisible' : ''
              }`}
            >
              {product.secretCount} secret
              {product.secretCount > 1 ? 's' : ''}
            </div>
          )}
          {product.teaser &&
            <div className='description'>{product.teaser}</div>
          }
        </div>
        <div className="footer">
        {whiteLabel.isInZone && 
          <div className="distance">
            <span className="adn adn-directions icon" />
            <DistanceConverted distance={product.distance} />
          </div>}
          <div className="business-hours">
            <span className={`adn adn-clock-outline icon`} />
            {schedulePath !== null && (
              <Text
                path={schedulePath}
                data={{ time: scheduleHour, date: scheduleDate }}
                className={`is-${scheduleStatus}`}
              />
            )}
          </div>
        </div>
      </Anchor>
    </CardWrapper>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object, //all product information
};

export default ProductCard;
