import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import styled from 'styled-components';

// import sun from 'ToolboxComponents/commons/weather/assets/sun.png';
// import moon from 'ToolboxComponents/commons/weather/assets/moon.png';
// import cloudy1 from 'ToolboxComponents/commons/weather/assets/cloudy-1.png';
// import night1 from 'ToolboxComponents/commons/weather/assets/night-1.png';
// import cloud from 'ToolboxComponents/commons/weather/assets/cloud.png';
// import rain2 from 'ToolboxComponents/commons/weather/assets/rain-2.png';
// import snowing2 from 'ToolboxComponents/commons/weather/assets/snowing-2.png';
// import tornado from 'ToolboxComponents/commons/weather/assets/tornado.png';
// import snowflake from 'ToolboxComponents/commons/weather/assets/snowflake.png';

const WeatherWrapper = styled.div`
  display: flex;
  align-items: center;

  .icon {
    height: 80px;
  }

  .temperature {
    font-size: ${props => `${48*props.theme.font.primaryFont.scaling/100}px`};
    color: ${props => props.theme.color.primaryColor};
  }
`;

const getImg = icon => `https://openweathermap.org/img/wn/${icon}@2x.png`;

// const getImg = icon => {
//   switch (icon) {
//     case 'clear-day':
//       return sun;
//     case 'clear-night':
//       return moon;
//     case 'partly-cloudy-day':
//       return cloudy1;
//     case 'partly-cloudy-night':
//       return night1;
//     case 'cloudy':
//       return cloud;
//     case 'rain':
//       return rain2;
//     case 'sleet':
//       return snowing2;
//     case 'snow':
//       return snowflake;
//     case 'wind':
//       return tornado;
//     case 'fog':
//       return tornado;
//     default:
//       return sun;
//   }
// };

const Weather = props => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  return (
    <WeatherWrapper className={`weather`} theme={theme}>
      <img src={getImg(whiteLabel.city.weatherIcon)} className={`icon`} alt={``} />
  <div className={`temperature`}>{whiteLabel.city.weatherTemperature}°c</div>
    </WeatherWrapper>
  );
};

Weather.propTypes = {};

export default Weather;
