import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const CityCardWrapper = styled.div`
.city-card-block {
  color: #fff;
  font-size: 1.4rem;
  padding: 10px;
  margin: 60px 0 5px 0 !important;
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
}

.city-card-link {
  text-decoration: none !important;
}

.city-card-link:hover {
  text-decoration: none !important;
}


.city-card-link:link {
  text-decoration: none !important;
}

.city-card-photo{
  text-align:center;
}

.city-card-text {
  margin-left: 12px;
}

.city-card-text {
  padding-top:15px;
  text-decoration: none;
  color:black;
}
.city-card-button{
  display:inline;
  color: white;
  background: ${props => { return props.theme.color.primaryColor }};
  border-radius: 90px;
  font-size:14px;
  width: 75%;
  font-weight:bold;
  max-width: 368px;
  max-height: 60px;
  border-color:unset;
  padding:10px 20px;
  text-align:center;
}

.city-card-text h2 {
  padding: 10px;
  padding-bottom: 0;
  text-transform: uppercase;
  font-size: 29px;
  font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
}

.city-card-text p {
  flex-grow:1;
  padding: 15px;
  padding-bottom:32px;
  padding-top: 0px;
  font-size:14px;
}


.city-card-block h2 {
  color: #fff;
  text-transform: uppercase;
}

@media only screen and (min-width: 1024px) {
  .city-card-block img {
    margin-top: -35px;
  }
  .city-card-block {
    padding: 8px 20px;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
  }
  .city-card-text{
    padding-right:60px;
  }
  .city-card-text p {
    font-size:29px;
  }
  .city-card-button{
    font-size:29px;
    margin-left:15px;
    padding: 10px 40px;
    width: 100%;
    max-width: 500px;

  }

}

@media only screen and (min-width: 1200px) {
  .city-card-block {
    padding: 18px 20px;
  }

}


`;


