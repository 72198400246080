const slidesProduct = [
  {
    id: 1,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.83,
    longitude: -0.57,
    prices: {
      list: [
        {
          maximum: 39,
          minimum: 8,
          category: "Adulte",
        },
        {
          maximum: 39,
          minimum: 8,
          category: "Enfant",
        },
      ],
      complement: {
        fr:
          "TARIFS BOWLING\r\n- du lundi au jeudi \r\n  La 1/2 heure = 15€\r\n  L'heure (avant 19h) = 29€\r\n  L'heure (après 19h) = 29€\r\n- vendredi et samedi \r\n    L'heure (avant 19h) = 29€\r\n    L'heure (après 19h) = 39€\r\n- dimanche \r\n    L'heure (avant 19h) = 39€\r\n    L'heure (après 19h) = 29€\r\n- veilles de fêtes \r\n    L'heure (avant 19h) = 29€\r\n    L'heure (après 19h) = 39€\r\n- Jours fériés \r\n    L'heure (avant 19h) = 39€ \r\n    L'heure (après 19h) = 29€\r\n- Vacances scolaires \r\n    L'heure (avant 19h) = 29€\r\n    L'heure (après 19h) = 39€\r\n\r\nTARIFS TRAMPOLINE\r\n12€/heure\r\nChaussettes adhérentes = 2€\r\n\r\nTARIFS KIDS PARK\r\n2 heures = 12€/heure\r\nGratuit pour les moins de 2 ans.\r\nFermeture du parc à 23h30.\r\n\r\nTARIFS BILLARDS\r\n- du lundi au jeudi \r\n   la 1/2 heure = 8€\r\n  L'heure (avant 19h) = 12€\r\n  L'heure (après 19h) = 12€\r\n- vendredi\r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€\r\n    L'heure (après 19h) = 14€\r\n- samedi \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€\r\n    L'heure (après 19h) = 16€\r\n- dimanche \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€\r\n    L'heure (après 19h) =129€\r\n- veilles de fêtes \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€\r\n    L'heure (après 19h) = 16€\r\n- Jours fériés \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€ \r\n    L'heure (après 19h) = 12€\r\n- Vacances scolaires \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€ \r\n    L'heure (après 19h) = 12€\r\nToute 1/2 heure entamée est dûe.",
      },
      description:
        "Adulte : de 8 à 39 €\nEnfant : de 8 à 39 €.\n\n\nTARIFS BOWLING\r\n- du lundi au jeudi \r\n  La 1/2 heure = 15€\r\n  L'heure (avant 19h) = 29€\r\n  L'heure (après 19h) = 29€\r\n- vendredi et samedi \r\n    L'heure (avant 19h) = 29€\r\n    L'heure (après 19h) = 39€\r\n- dimanche \r\n    L'heure (avant 19h) = 39€\r\n    L'heure (après 19h) = 29€\r\n- veilles de fêtes \r\n    L'heure (avant 19h) = 29€\r\n    L'heure (après 19h) = 39€\r\n- Jours fériés \r\n    L'heure (avant 19h) = 39€ \r\n    L'heure (après 19h) = 29€\r\n- Vacances scolaires \r\n    L'heure (avant 19h) = 29€\r\n    L'heure (après 19h) = 39€\r\n\r\nTARIFS TRAMPOLINE\r\n12€/heure\r\nChaussettes adhérentes = 2€\r\n\r\nTARIFS KIDS PARK\r\n2 heures = 12€/heure\r\nGratuit pour les moins de 2 ans.\r\nFermeture du parc à 23h30.\r\n\r\nTARIFS BILLARDS\r\n- du lundi au jeudi \r\n   la 1/2 heure = 8€\r\n  L'heure (avant 19h) = 12€\r\n  L'heure (après 19h) = 12€\r\n- vendredi\r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€\r\n    L'heure (après 19h) = 14€\r\n- samedi \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€\r\n    L'heure (après 19h) = 16€\r\n- dimanche \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€\r\n    L'heure (après 19h) =129€\r\n- veilles de fêtes \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€\r\n    L'heure (après 19h) = 16€\r\n- Jours fériés \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€ \r\n    L'heure (après 19h) = 12€\r\n- Vacances scolaires \r\n    la 1/2 heure = 8€\r\n    L'heure (avant 19h) = 12€ \r\n    L'heure (après 19h) = 12€\r\nToute 1/2 heure entamée est dûe.",
    },
    tags: [1, 2, 3],
    secrets: [
      {
        id: 1111,
        streamCode: null,
        title: "A la découverte des mille et un flacons !",
        description:
          "Le saviez-vous ? Au moins l’un de vos flacons de parfum est né dans la Vallée de la Bresle. De sa découverte en Egypte aux machines automatiques, rendez-vous au Musée Traditions Verrières. Des passionnés vous conteront l’histoire du verre et la magie du flaconnage. N’oubliez pas de rendre visite à nos artisans verriers au Tréport, souffleur de verre ou perlier……",
        genericPhotos: [
          "secret/1111/EU-Mus-C3-A9e-20des-20Traditions-20Verri-C3-A8res-0021-V3.jpg",
        ],
        coordinates: '{"type":"Point","coordinates":[1.42869337,50.054714]}',
        ownerMail: null,
        firstName: "Magali",
        photo: "profile/3354/1556778706242.jpg",
        km: 538.005441285216,
        close: false,
        productId: 29224,
        productTitle: "Musée du verre et des Traditions Verrières ",
        city: "EU",
        score: 0,
        secretCount: 1,
      },
      {
        id: 1214,
        streamCode: null,
        title: "Sur Secrets Normands on peut avoir la tête en l’air ",
        description:
          "Qui ne connaît pas Regulus, l'automate, qui sonne depuis 1826 les heures au clocher de l'église Saint-Nicolas ?\nNiché dans le clocher, « Régulus » représente un général romain, symbole de la parole donnée, œuvre de M. Martin qui s’installa à Beaumont le Roger vers 1796 pour y fonder une maison d’horlogerie... Venez lui rendre une petite visite, Régulus vous attend !\n© JP.COPITET",
        genericPhotos: ["secret/1214/R-C3-A9gulus-20-JPCopitet.JPG"],
        coordinates: '{"type":"Point","coordinates":[0.77625662,49.0802078]}',
        ownerMail: null,
        firstName: "Justine",
        photo: null,
        km: 424.742736995263,
        close: false,
        productId: 28846,
        productTitle: "Beaumont-le-Roger",
        city: "BEAUMONT-LE-ROGER",
        score: 0,
        secretCount: 4,
      },
      {
        id: 593,
        streamCode: null,
        title: "Un footing au bord des greens",
        description:
          "Le parc du golf du Vaudreuil est idéal pour un footing. Revêtement impeccable, environnement magnifique et en prime la possibilité de se rafraîchir au bar du golf (terrasse recommandée !)",
        genericPhotos: [
          "https://cdt14.media.tourinsoft.eu/upload/Club-House---Golf-du-Vaudreuil.jpg",
          "https://cdt14.media.tourinsoft.eu/upload/Terrasse---Golf-du-Vaudreuil.jpg",
          "https://cdt14.media.tourinsoft.eu/upload/Parcours---Golf-du-Vaudreuil.jpg",
        ],
        coordinates: '{"type":"Point","coordinates":[1.2215079,49.2560976]}',
        ownerMail: null,
        firstName: "Laurent",
        photo: "https://graph.facebook.com/1677847162270553/picture?type=large",
        km: 447.936315971372,
        close: false,
        productId: 1797,
        productTitle: "Golf PGA France du Vaudreuil",
        city: "LE VAUDREUIL",
        score: 0,
        secretCount: 8,
      },
      {
        id: 643,
        streamCode: "SECNOR014V52NM46",
        title: "Des mots et des douceurs",
        description:
          "Un café librairie, chaleureux, face à la mer, où il fait bon passer tout un après-midi au chaud parmi les livres ou sur belle digue de Saint-Aubin-sur-mer. Le petit plus : les délicieux gâteaux maison et les soirées musicales!",
        genericPhotos: [
          "http://normandie.media.tourinsoft.eu/upload/6CE16227-A37F-4DB7-A083-4F28E2214EFE/restaurants-saintaubin-auxbainsdesmots-2.jpg|Agnès Girard",
        ],
        coordinates: '{"type":"Point","coordinates":[-0.392056,49.3315233]}',
        ownerMail: "pc.bougy@sfr.fr",
        firstName: "Catherine",
        photo: null,
        km: 454.965605742769,
        close: false,
        productId: 40967,
        productTitle: "Aux bains des mots",
        city: "SAINT-AUBIN-SUR-MER",
        score: 0,
        secretCount: 5,
      },
    ],
  },
  {
    id: 2,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open's at 10`,
    latitude: 44.84,
    longitude: -0.58,
    tags: [1, 2, 3],
    secrets: [{ id: 29 }],
  },
  {
    id: 3,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `closed`,
    latitude: 44.85,
    longitude: -0.59,
    tags: [4, 5, 6],
    secrets: [{ id: 29 }],
  },
  {
    id: 4,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.86,
    longitude: -0.57,
    tags: [4, 5, 6],
    secrets: [{ id: 29 }],
  },
  {
    id: 5,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.87,
    longitude: -0.58,
    tags: [7, 8, 9],
    secrets: [{ id: 29 }],
  },
  {
    id: 6,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.88,
    longitude: -0.59,
    tags: [7, 8, 9],
    secrets: [{ id: 29 }],
  },
  {
    id: 7,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.89,
    longitude: -0.57,
    tags: [7, 8, 9],
    secrets: [{ id: 29 }],
  },
  {
    id: 8,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.9,
    longitude: -0.58,
    tags: [10, 11, 12],
    secrets: [{ id: 29 }],
  },
  {
    id: 9,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.91,
    longitude: -0.59,
    tags: [1, 2, 3],
    secrets: [{ id: 29 }],
  },
  {
    id: 10,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.92,
    longitude: -0.57,
    tags: [1, 2, 3],
    secrets: [{ id: 29 }],
  },
  {
    id: 11,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.93,
    longitude: -0.58,
    tags: [3, 5, 6],
    secrets: [{ id: 29 }],
  },
  {
    id: 12,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.94,
    longitude: -0.59,
    tags: [3, 5, 6],
    secrets: [{ id: 29 }],
  },
  {
    id: 13,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.95,
    longitude: -0.57,
    tags: [3, 5, 6],
    secrets: [{ id: 29 }],
  },
  {
    id: 14,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.96,
    longitude: -0.58,
    tags: [4, 1, 10],
    secrets: [{ id: 29 }],
  },
  {
    id: 15,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.97,
    longitude: -0.59,
    tags: [4, 1, 10],
    secrets: [{ id: 29 }],
  },
  {
    id: 16,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 44.99,
    longitude: -0.57,
    tags: [4, 1, 10],
    secrets: [{ id: 29 }],
  },
  {
    id: 17,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 45,
    longitude: -0.58,
    tags: [4, 1, 10],
    secrets: [{ id: 29 }],
  },
  {
    id: 18,
    title: `Locabike, location de velos super cool pas loin`,
    city: `à Carcans`,
    description: `40 ans d'experience ! En plein coeur de Lacanau-Ocean, 40 annees d'experience dans la location de velos. Un tres large eventail de produits et un tres bon conseiller pour choisir le velo de vos vacances !`,
    photo: `https://picsum.photos/500`,
    distance: 64,
    hours: `open`,
    latitude: 45.01,
    longitude: -0.59,
    tags: [1, 2, 3],
    secrets: [{ id: 29 }],
  },
];

export default slidesProduct;
