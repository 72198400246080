import React from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";

const NameWrapper = styled.div`
`;

const Name = props => {
  return <NameWrapper className={`name`}>
    Pierre
  </NameWrapper>;
};

Name.propTypes = {};

export default Name;
