import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import {WhiteLabelContext } from "ToolboxUtils/web/context/context";
import HomeTitle from 'ToolboxComponents/webapp/pages/home-view/components/home-title/home-title';
import HomeTitleWeather from 'ToolboxComponents/webapp/pages/home-view/components/home-title-weather/home-title-weather';
import HomeSliderProduct from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-product/home-slider-product';
import HomeSliderRestos from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-restaurants/home-slider-restaurants';
import HomeSliderActivites from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-activites/home-slider-activites';
import HomeSliderSitesToVisit from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-sites-to-visit/home-slider-sites-to-visit';
import HomeSliderSecrets from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-secrets/home-slider-secrets';
import HomeSliderEvents from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-events/home-slider-events';
import HomeSliderCircuits from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-circuits/home-slider-circuits';
import HomeSliderHeartstoppers from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-heartstoppers/home-slider-heartstoppers';
import HomeSliderPepites from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-pepites/home-slider-pepites';
import HomeSliderMoments from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-moments/home-slider-moments';
import HomeSliderUpcomingEvents from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-upcoming-events/home-slider-upcoming-events';
import HomeSliderSuperWhisperer from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-super-whisperer/home-slider-super-whisperer';
import HomeSliderTrust from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-trust/home-slider-trust';
import HomeSliderDesires from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-desires/home-slider-desires';
import HomeDestination from 'ToolboxComponents/webapp/pages/home-view/components/home-destination/home-destination';
import HomeViewWrapper from 'ToolboxComponents/webapp/pages/home-view/home-view.style';
import Prehome from 'ToolboxComponents/webapp/pages/home-view/components/prehome/prehome';
import PrehomeSlider from 'ToolboxComponents/webapp/pages/home-view/components/prehome-slider/prehome-slider';
import HomePresta from 'ToolboxComponents/webapp/pages/home-view/components/home-presta/home-presta';


const HomeView = props => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [blockComponents, setBlockComponents] = useState({});

  const {
    addFavorite,
    deleteFavorite,
    onDesireSelected,
    whisperers,
    nbSecrets,
    nbWhisperers,
  } = props;


 

  
  //on page init, go to screen top
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  

  useEffect(() => {
    const {
      products,
      secrets,
      currentEvent,
      events,
      circuits,
      majorsThemes,
      heartstoppersThemes,
      moments,
      upcomingEvents,
      desires,
      dayTimes,
      blocks,
      prehomeSecrets,
      restaurants,
      activites,
      sitesToVisit,
      restoDesireName,
      activitesDesireName,
      sitesDesireName
    } = props;

   
    

    setBlockComponents({
      1: !!products && products.length > 0 && (
        <HomeSliderProduct
          key={1}
          products={products}
          addFavorite={event => addFavorite(event)}
          deleteFavorite={event => deleteFavorite(event)}
          sliderTitle={`page.homepage.productsSliderTitle`}
        />
      ),
      2: !!secrets && secrets.length > 0 && (
        <HomeSliderSecrets key={2} secrets={secrets} />
      ),
      3: !!upcomingEvents && upcomingEvents.length > 0 && (
        <HomeSliderUpcomingEvents key={3} upcomingEvents={upcomingEvents} />
      ),
      4: !!events && events.length > 0 && (
        <HomeSliderEvents key={4} currentEvent={currentEvent} events={events} />
      ),
      5: <HomeSliderSuperWhisperer key={5} />,
      6: !!dayTimes && !!desires && desires.length > 0 && (
        <HomeSliderDesires
          key={6}
          dayTimes={dayTimes}
          desires={desires}
          onDesireSelected={id => onDesireSelected(id)}
        />
      ),
      7: !!circuits && circuits.length > 0 && (
        <HomeSliderCircuits key={7} circuits={circuits} />
      ),
      /*!!moments && moments.length > 0 && (
        <HomeSliderMoments key={8} moments={moments} />
      ),*/ 
      10: <HomeDestination key={9} />,
      11: <>
      <Prehome
        users={whisperers}
        nbSecrets={nbSecrets}
        nbWhisperers={nbWhisperers}
        hasStarWhisperers={props.hasStarWhisperers}
      />
      {whiteLabel.privatePartnerId ? null : ( (whiteLabel.id === 10 || whiteLabel.id === 24)  ? <HomeTitle /> : <HomeTitleWeather />)}
      </>,
      12: <>
      <PrehomeSlider
        users={whisperers}
        nbSecrets={nbSecrets}
        prehomeSecrets={prehomeSecrets}
        nbWhisperers={nbWhisperers}
        hasStarWhisperers={props.hasStarWhisperers}
      />
      </>,
      14: !!restaurants && restaurants.length > 0 && (
        <HomeSliderRestos
          key={14}
          products={restaurants}
          addFavorite={event => addFavorite(event)}
          deleteFavorite={event => deleteFavorite(event)}
          sliderTitle={`page.homepage.restaurantsSliderTitle`}
          desireName={restoDesireName}
        />
      ),
      15: !!activites && activites.length > 0 && (
        <HomeSliderActivites
          key={15}
          products={activites}
          addFavorite={event => addFavorite(event)}
          deleteFavorite={event => deleteFavorite(event)}
          sliderTitle={`page.homepage.activitesSliderTitle`}
          desireName={activitesDesireName}
        />
      ),
      16: !!sitesToVisit && sitesToVisit.length > 0 && (
        <HomeSliderSitesToVisit
          key={16}
          products={sitesToVisit}
          addFavorite={event => addFavorite(event)}
          deleteFavorite={event => deleteFavorite(event)}
          sliderTitle={`page.homepage.sitesSliderTitle`}
          desireName={sitesDesireName}
        />
      ),
      17: !!majorsThemes && majorsThemes.length > 0 && (
        <HomeSliderHeartstoppers key={17} products={majorsThemes} title={'Les incontournables'}/>
      ),
      18: !!heartstoppersThemes && heartstoppersThemes.length > 0 && (
        <HomeSliderHeartstoppers key={18} products={heartstoppersThemes} title={'Nos coups de coeur'} />
      ),
      19: whiteLabel.customText && whiteLabel.customText.home && (
        <HomePresta key={19} text={whiteLabel.customText.home} />
      ),
    });
    // eslint-disable-next-line
  }, [
    props.products,
    props.secrets,
    props.currentEvent,
    props.events,
    props.circuits,
    props.moments,
    props.upcomingEvents,
    props.desires,
    props.dayTimes,
    props.blocks,
    props.restaurants,
    props.activites,
    props.sitesToVisit,
    props.restoDesireName,
    props.activitesDesireName,
    props.sitesDesireName,
    props.majorsThemes,
    props.heartstoppersThemes
  ]);

  return (
    <HomeViewWrapper theme={theme}>
      {props.blocks.map(x => blockComponents[x])}
      { whiteLabel.id !== 10 && whiteLabel.id !== 24  &&
        <HomeSliderTrust />
      } 
    </HomeViewWrapper>
    
  );
};

HomeView.propTypes = {
  
};

export default HomeView;
