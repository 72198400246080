import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import styled from 'styled-components';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import MomentsHomeCard from 'ToolboxComponents/commons/cards/moments-home-card/moments-home-card';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';

const HomeSliderMomentsWrapper = styled.div`
  padding: 30px 0 20px;

  background: ${props => props.tertiaryColor};
  .swiper-pagination-design {
    top: 0;
    margin-bottom: 30px;
  }

  .home-slider-moments-title {
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    text-align: center;
  }

  .home-slider-moments-main {
    font-family: ${props => props.theme.font.secondaryFont.name};
    text-transform: uppercase;
    font-size: ${props => `${20*props.theme.font.secondaryFont.scaling/100}px`};
  }

  .home-slider-moments-sub {
    color: ${props => props.primaryColor};
    margin-bottom: 20px;
  }

  .home-slider-moments-more {
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    text-align: center;
    margin-top: 20px;

    a {
      color: black;
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .swiper-container {
      // padding: 30px 80px;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
    }
    .home-slider-moments-more {
      text-align: right;
      padding-right: 80px;
    }
  }
`;

const HomeSliderMoments = props => {
  const theme = useContext(ThemeContext).state;
  const { moments } = props;
  const { primaryColor, tertiaryColor } = useContext(ThemeContext).state.color;
  // const [activeProducts, setActiveProducts] = useState(products);

  return (
    <HomeSliderMomentsWrapper
      className={`home-slider-moments`}
      primaryColor={primaryColor}
      tertiaryColor={tertiaryColor}
      theme={theme}
    >
      <div className={`home-slider-moments-title`}>
        <div className={`home-slider-moments-main`}>
          <Text path={`page.homepage.momentsTitle`} />
        </div>
        <div className={`home-slider-moments-sub`}>
          <Text path={`page.homepage.momentsSubtitle`} />
        </div>
      </div>
      <Slider
        mobile={{
          slidesPerView: 1.2,
          spaceBetween: 30,
          slidesPadding: 10,
        }}
        desktop={{
          slidesPerView: 3,
          centerInsufficientSlides: true,
          // spaceBetween: 160,
        }}
      >
        {moments.slice(0, 3).map((moment, index) => (
          <MomentsHomeCard
            key={`moment-home-slide--${index}`}
            moment={moment}
          />
        ))}
      </Slider>
      <div className={`home-slider-moments-more`}>
        <Anchor code="home">
          <Text path={`page.homepage.eventsMore`} />
        </Anchor>
      </div>
    </HomeSliderMomentsWrapper>
  );
};

HomeSliderMoments.propTypes = {};

export default HomeSliderMoments;
