import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const SecretsWrapper = styled.div``;

const Secrets = props => {
  return (
    <SecretsWrapper className={`secrets`}>
      <div className={`nb-secrets`}>13</div>
      <div className={`secret-text`}>secrets chuchotés</div>
      <div className={`secret-see-more`}>à découvrir ici</div>
    </SecretsWrapper>
  );
};

Secrets.propTypes = {};

export default Secrets;
