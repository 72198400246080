import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const HomeViewWrapper = styled.div`
  .title-block {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 0;

    @media (min-width: ${isMobileBreakpoint}px) {
      padding: 40px 20px 0;
    }
  }

  .block-title {
    height: 40px;
    margin: 0 0 24px 0;
    position: relative;
    hr {
      width: 100%;
      top: 50%;
      height: 1px;
      position: absolute;
      margin: 0;
      border-top: 1px solid black;
    }
    .title, .sub-title {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 40px;
      z-index: 1;
      position: absolute;
      margin: 0px auto;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin-top: -20px;
      span {
        display: block;
        background-color: white;
        width: 520px;
      }
    }
    .title.alone {
      margin-top: 0px;
    }
    .sub-title {
      font-size: 18px;
      margin-top: 18px;
      color:  ${props => props.theme.color.primaryColor};
      span {
        height: 40px;
      }
    }
  }



  .button-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .button {
      background: ${props => props.theme.color.primaryColor};
      font-weight: bold;
      color: white;
      width: 330px;
      border-radius: 16px;
      padding: 5px;
      text-align: center;
      margin-top: 26px;
    }
  }


  @media (max-width: 767px) {
    .block-title {
      margin: 0;
      .title, .sub-title {
        margin: 0;
        font-size: 18px;
        span {
          width: 330px;
        }
      }
      .sub-title {
        height: 28px;
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 25px;
        span {
          height: 28px;
        }
      }
    }
    .block-title.sub {
      margin-bottom: 30px;
    }
    .button-area {
      .button {
        margin: 0;
        width: 250px;
      }
    }
  }

  .home-time {
    @media (min-width: ${isMobileBreakpoint}px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .home-slider-secrets,
  .home-slider-events,
  .home-slider-moments,
  .home-slider-super-whisperer {
  }

  .home-slider-product,
  .home-slider-desires,
  .home-slider-events,
  .home-slider-secrets,
  .home-slider-circuits,
  .home-slider-upcoming-events {
    margin-bottom: 30px;

    @media (min-width: ${isMobileBreakpoint}px) {
      margin: 50px 0 100px 0;
    }
  }

  .home-slider-product {
  }
  .home-slider-secrets {
  }
  .home-slider-events {
  }
  .home-slider-moments {
  }
  .home-slider-upcoming-events {
  }
  .home-slider-super-whisperer {
  }
  .home-slider-trust {
  }
`;

export default HomeViewWrapper;
