import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const HeartCardWrapper = styled.div`
padding-bottom: 5px;

&:hover {
  cursor: pointer;
}

.home-circuits {
  position: relative;
  margin-top: 30px;
  height: 367px;
  margin-bottom: 60px;
}

.home-circuits-inner {
  display:flex;
  flex-direction: column;
  height: 100%;
}

.home-circuits-photo {
  height: 72%;
}

.home-circuits-photo1 {
  object-fit: cover;
  background-position-x: 60%;
}

.home-circuits-photo2 {
  object-fit: cover;
  background-position-x: 100%;
  background-position-y: 22%;
}

.home-circuits-text {
  padding-top: 10px;
  height: 28%;
  background-color: ${props => props.theme.color.primaryColor};;
  color: white;
  text-align: center;
}

.home-circuits-title {
  padding: 10px;
  padding-bottom: 0;
  text-transform: uppercase;
  font-size: ${props => `${21*props.theme.font.primaryFont.scaling/100}px`};
  font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  line-height : 26px;
}

.home-circuits-description {
  padding: 15px;
  padding-top: 0px;
  font-size:14px;
}

.home-circuits-button-area {
  position: absolute;
  bottom: -30px;
 width: 100%;
  height: 20px;
  padding: 8px 8px 14px 8px;
  text-align: center;
}

.home-circuits-button {
  display: inline-block;
  border-radius: 16px;
  width: 60%;
  margin: 0 auto;
  text-align: center;
  padding: 6px;
  background: #3d3d3d;
  font-weight: bold;
  color: #fff;
}

@media only screen and (max-width: 767px) {

  .home-circuits {
    height: 305px;
    padding: 0 -10px;
    margin-top: 0;
  }

   .home-circuits-title {
    padding: 10px;
    padding-bottom: 0;
    font-size: ${props => `${18*props.theme.font.primaryFont.scaling/100}px`};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    line-height: 24px;
  }

  .home-circuits-description {
    padding: 11px;
    padding-top: 0px;
    font-size:14px;
  }

  .home-circuits-photo {
    height: 205px;
  }

  .home-circuits-photo2 {
    background-position-x: 72%;
  }

  .home-circuits-text {
    height: 100px;
  }

}

`;


