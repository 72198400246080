import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, WebappContext } from 'ToolboxUtils/web/context/context';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import DesireHomepageSlide from 'ToolboxComponents/commons/cards/desire-homepage-product-card/desire-homepage-product-card';

function getSliderRatio() {
  const totalWidth = document.body.offsetWidth;
  const contentWidth = document.getElementById("main-nav").firstChild.offsetWidth;
  const ratio = (totalWidth - SPACE_BETWEEN_SLIDES) / (contentWidth);
  return ratio < 1.1 ? 1 : ratio;
}

const SliderHpDesires = ({ desires, onDesireSelected, onClick }) => {
  const { primaryColor } = useContext(ThemeContext).state.color;
  const [desireSelected, setDesireSelected] = useState();
  useEffect(() => {
    if (desireSelected !== undefined) {
      onDesireSelected(desireSelected);
    }
  }, [desireSelected]);

  return (
    <Slider
      desktop={{
        slidesPerView: 4,
        spaceBetween: 40,
        dots:false,
        slidesPadding: 20,
        centeredSlides: false,

      }}
      mobile={{
        slidesPerView: 1.2,
        dots:true,
        slidesPerGroup: 1,
        arrows: false,
        centeredSlides: true,
        spaceBetween: 20,
        slidesPadding: 10,
      }}
      dotsColorActive={primaryColor}
    >
      {desires.map((desire, index) => (
        <DesireHomepageSlide
          key={index}
          index={index}
          desire={desire}
          setDesireSelected={desire => setDesireSelected(desire)}
          onClick={onClick}
        />
      ))}
    </Slider>
  );
};

SliderHpDesires.propTypes = {
  desires: PropTypes.array.isRequired,
  onDesireSelected: PropTypes.func.isRequired,
};

export default SliderHpDesires;
