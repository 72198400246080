import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import styled from 'styled-components';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import TrustHomeCard from 'ToolboxComponents/commons/cards/trust-home-card/trust-home-card';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';

const HomeSliderTrustWrapper = styled.div`
  padding: 30px 0 20px;

  .swiper-pagination-design {
    top: 20px;
  }

  .swiper-pagination-bullet {
    background: ${props => props.tertiaryColor};
  }

  .home-slider-trust-title {
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)};
    text-align: center;
  }

  .home-slider-trust-main {
    font-family: ${props => props.theme.font.secondaryFont.name};
    text-transform: uppercase;
    font-size: ${props => `${(26 - isMobileView() * 3)*props.theme.font.secondaryFont.scaling/100}px`};
    color: #3e3e3e;
  }

  .home-slider-trust-sub {
    margin-bottom: 20px;
    color: ${props => props.primaryColor};
  }

  .home-slider-trust-more {
    text-align: center;
    margin-top: 20px;

    a {
      color: black;
    }
  }
`;

const HomeSliderTrust = props => {
  const theme = useContext(ThemeContext).state;
  const { primaryColor, tertiaryColor } = useContext(ThemeContext).state.color;
  // const [activeProducts, setActiveProducts] = useState(products);

  return (
    <HomeSliderTrustWrapper
      className={`home-slider-trust`}
      primaryColor={primaryColor}
      tertiaryColor={tertiaryColor}
      theme={theme}
    >
      <div className={`home-slider-trust-title`}>
        <div className={`home-slider-trust-main`}>
          <Text path={`page.homepage.trustTitle`} />
        </div>
        <div className={`home-slider-trust-sub`}>
          <Text path={`page.homepage.trustSubtitle`} />
        </div>
      </div>
      <Slider
        mobile={{
          slidesPerView: 1,
          dots: true,
        }}
        desktop={{
          slidesPerView: 3,
          centerInsufficientSlides: true,
        }}
        dotsColorActive={primaryColor}
      >
        <TrustHomeCard name={'page.homepage.trustSlide1'} icon={`check-bold`} />
        <TrustHomeCard name={'page.homepage.trustSlide2'} icon={`star`} />
        <TrustHomeCard
          name={'page.homepage.trustSlide3'}
          icon={`account-multiple`}
        />
      </Slider>
    </HomeSliderTrustWrapper>
  );
};

HomeSliderTrust.propTypes = {};

export default HomeSliderTrust;
