import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/components/is-mobile-view';


export const CardWrapper = styled.div`
  background: white;
  margin: 20px 0;
  font-size: ${props => `${14*props.theme.font.primaryFont.scaling/100}px`};
  transition: all 0.1s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

  transform: ${props =>
    props.isSelected === true ? 'scale(1.025)' : 'scale(1)'};

  .header {
    position: relative;
    z-index: 2;
    height: 233px;
    .image {
      width: 100%;
      height: 210px;
      object-fit: cover;
    }
    }
  }

  .content {
    padding: 0px 20px 10px;

    .title,
    .city,
    .description,
    .nb-secrets {
      padding: 0 5px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .title,
    .city {
      -webkit-line-clamp: 1;
    }

    .title {
      font-size: ${props => `${20*props.theme.font.primaryFont.scaling/100}px`};
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      line-height: 1.1;
    }

    .nb-secrets {
      color: ${props => props.theme.color.primaryColor};
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      margin-bottom: 5px;
    }

    .description {
      margin-bottom: 6px;
      -webkit-line-clamp: 2;
      line-height: 14px;
    }
  }

  .footer {
    padding: 0 20px 10px;
    display: flex;

    .distance,
    .business-hours {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 5px;
        font-size: 24px;
        color: black;
      }
    }

    .distance {
      margin-right: 10px;
    }

    .business-hours {
      color: green;
      color: ${props => props.product.hours === 'open' && 'green'};
      color: ${props => props.product.hours === 'closed' && 'red'};
    }
  }

  .is-on {
    color: green;
  }

  .is-off {
    color: red;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    margin: 20px 0;
    box-shadow: ${props =>
      props.isSelected === true
        ? '2px 2px 20px 5px rgba(0, 0, 0, 0.15)'
        : '2px 2px 20px 5px rgba(0, 0, 0, 0.1)'};
    .header {
      .image {
        height: 100%;
      }
    }
    .content {
      padding-top: 20px;
    }
  }
`;
