import React, { useEffect, useState, useContext } from 'react';
import LinesEllipsis from 'react-lines-ellipsis'
// import PropTypes from "prop-types";
import { WhiteLabelContext, ThemeContext } from 'ToolboxUtils/web/context/context';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import PrehomeSliderWrapper from 'ToolboxComponents/webapp/pages/home-view/components/prehome-slider/prehome-slider.style';
// import heroImage1 from 'ToolboxAssets/img/prehome/1.jpg';
// import heroImage2 from 'ToolboxAssets/img/prehome/2.jpg';
// import heroImage3 from 'ToolboxAssets/img/prehome/3.jpg';
import heroImage4 from 'ToolboxAssets/img/prehome/4.jpg';
// import heroImage5 from 'ToolboxAssets/img/prehome/5.jpg';
import SuperWhispererImg from 'ToolboxAssets/img/super-whisperer-400.png';
import getScrollbarWidth from 'ToolboxUtils/web/style/get-scrollbar-width';
import Text from 'ToolboxComponents/commons/texts/text/text';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import Slider from "ToolboxComponents/commons/sliders/slider/slider/slider";

const SPACE_BETWEEN_SLIDES = 40;
// const heroImage = [heroImage1,heroImage2,heroImage3,heroImage4,heroImage5][Math.floor(Math.random() * 5)]

function getSliderRatio() {
  const totalWidth = document.body.offsetWidth;
  const contentWidth = document.getElementById("main-nav").firstChild.offsetWidth;
  const ratio = (totalWidth - SPACE_BETWEEN_SLIDES) / (contentWidth);
  return ratio < 1.1 ? 1 : ratio;
}

const Prehome = props => {
  const { nbSecrets, prehomeSecrets, nbWhisperers } = props;
  const [isMobile, setIsMobile] = useState(isMobileView());
  const [maxWhisperers, setMaxWhisperers] = useState(isMobileView() ? 3 : 5);
  const [secrets, setSecrets] = useState(prehomeSecrets && prehomeSecrets.slice(0, maxWhisperers));
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swiper, setSwiper] = useState(null);


  const [textualContent] = useContext(WhiteLabelContext).usePath(
    'textualContent',
  );
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const theme = useContext(ThemeContext).state;

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    setSecrets(prehomeSecrets.slice(0, maxWhisperers));
  }, [prehomeSecrets])

  useEffect(() => setScrollbarWidth(getScrollbarWidth()), [document.body.clientWidth]);
  return (
    <PrehomeSliderWrapper className={`full-width`} theme={theme} scrollbarWidth={scrollbarWidth} hasStarWhisperers={props.hasStarWhisperers}>
      <div className={`prehome`}>
      <Slider
        id="slider"
        getRealCurrentSlideIndex = {index => setCurrentSlide(index)}
        slideTo= {null}
        arrowsColor= "#fff"
        setSwiper= {setSwiper}
        general= {{
          autoplay: {
            delay: 5000,
          },
          on: {
            // slideChangeTransitionStart: () => {console.log("start")},
            // slideChangeTransitionEnd: () => {console.log("end")},
            // beforeTransitionStart:      () => {console.log("before")},
          },
        }} 
        desktop={{
          arrowsWidth: "80",
          arrowsHeight: "80",
          arrowsInside: true,
          dots: false,
          arrows: false,
          centeredSlides: true,
          spaceBetween: SPACE_BETWEEN_SLIDES,
          slidesPerView: getSliderRatio(), 
          lazy: true,
          speed: 4000,
          slidesPadding: 0,
        }}
        mobile={{
          arrowsWidth: "80",
          arrowsHeight: "80",
          arrowsInside: true,
          loop: true,
          arrows: false,
          dots: false,
          slidesPerView: 1, 
          lazy: true,
          speed: 4000
        }}
      >
        {secrets.map((secret, index) => (
          <ImageResize
          key={index}
          width={`large`}
          className={`prehome-hero-image`}
          src={
            secret.photo
          }
         />
        ))}
      </Slider>
      <section className={`prehome-banner`}>
        <div id={"prehome-banner-content"} className='content'>
          <div className="arrow left-arrow" onClick={() => {swiper.slidePrev()}}></div>
          <div className="arrow right-arrow" onClick={() => {swiper.slideNext()}}></div>
          <div className={`users`}>
            <h1 className={`title-desktop`}>
              <span className={`secret-count`}>
                <Text
                  path={`page.homepage.prehomeNbSecrets`}
                  data={{ nbSecrets }}
                />
              </span>
              <span className={`text`}>
                <Text path={`page.homepage.prehomeTitle1`} />
              </span>
              <span className={`text`}>
                <Text path={`page.homepage.prehomeTitle2`} />
              </span>
            </h1>
            {prehomeSecrets !== undefined &&
              secrets.map((secret, index) => {
                  return (
                    <div className='whisperers'>
                      <Anchor
                        code="product"
                        pageParams={`/${secret.productId}/`}
                        className={`user`}
                        key={index}
                      >
                      <div className={'secret' + (index === currentSlide ? ' visible' : '') + ' position' + index}>
                        <div className='secret-title'>{secret.title}</div>
                        <LinesEllipsis
                          className='secret-description' style={{
                            maxHeight: document.getElementById("slider") && document.getElementById("slider").offsetHeight - 140
                          }}
                          text={secret.description}
                          maxLine={isMobile ? '2' : '3'}
                          ellipsis={<span>... <span className='see-more'>voir plus</span></span>}
                          trimRight
                          basedOn='letters'
                        />
                        <div className='secret-author'><Text path={`page.homepage.prehomeWhisperedBy`} /> {secret.accountName}</div>
                        <div className='secret-tail' style={
                          isMobile ? {left: document.getElementById("whisperer-"+index) && document.getElementById("whisperer-"+index).offsetLeft + 4} : {}
                          }>
                        </div>
                      </div>
                      </Anchor>
                    <Anchor
                      code="public"
                      pageParams={`/${secret.accountId}`}
                      className={`user`}
                      key={index}
                    >
                      <div id={"whisperer-" + index} className={`image`}>
                        <ImageResize src={secret.accountPhoto} />
                      </div>
                      <div className={`name`}>{secret.accountName}</div>
                    </Anchor>
                    </div>
                  );
              })}
            <Anchor code="whisperers" className={`user`}>
              <div className={`image`}>
                 <ImageResize src={SuperWhispererImg} />
              </div>
              <div className={`name superWhisperers`}>
                <Text path={`page.homepage.prehomeAnd`} /> {isMobile === true ? nbWhisperers - 3 : nbWhisperers - 6} <Text path={`page.homepage.prehomeWhisperers`} />
              </div>
            </Anchor>
          </div>
        </div>
      </section>
        <div className={`subtitle-mobile`}>
          <h1 className={`title-mobile`}>
              <span className={`secret-count`}>
                <Text
                  path={`page.homepage.prehomeNbSecrets`}
                  data={{ nbSecrets }}
                />
              </span>
              &nbsp;
              <span className={`text`}>
                <Text path={`page.homepage.prehomeTitle1`} />
              </span>
              &nbsp;
              <span className={`text`}>
                <Text path={`page.homepage.prehomeTitle2`} />
              </span>
            </h1>
            {/*
              <div className="subtitle-text">
              <Text path={`page.homepage.prehomeSubtitleMobile1`} /> <br/>
              <Text path={`page.homepage.prehomeSubtitleMobile2`} />
              </div>
            */}
        </div>
      </div>
      {/*
      <div className={`subtitle-dekstop`}>
        <div className= {`content`}>
          Faites-nous confiance, 
          on a la chance de vivre ici toute l'année
        </div>
      </div>
      */}
    </PrehomeSliderWrapper>
  );
};

// Prehome.propTypes = {};

export default Prehome;
