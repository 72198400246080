import styled from 'styled-components';

export const DesireWrapper = styled.div`
  margin-bottom: 5px;
  .btn-search {
    background: none;
    border: 0;
    width: ${props => (props.isMobile ? '95%' : '100%')};
    padding: 0;
    text-align: center;
    color: #000000;
    box-shadow: 2px 2px 4px rgba(0,0,0,.3);
    margin-bottom: ${props => (props.isMobile ? '15px' : '')};
  }
  .btn-search img {
    border: 2px solid white;
    border-bottom: 0;

    margin-bottom: 5px;
    width: 100%;
  }
  .search-form-label {
    height: ${props => (props.isMobile ? '50px' : '')};
    display: ${props => (props.isMobile ? 'flex' : 'block')};
    justify-content: ${props => (props.isMobile ? 'center' : '')};
    align-items: ${props => (props.isMobile ? 'center' : '')};
    margin-bottom: 5px;
    font-size: ${props => `${18*props.theme.font.primaryFont.scaling/100}px`};
    // font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  }
  .btn-search.active, .btn-search:hover {
    background: ${props => { return props.theme.color.primaryColor }};
    color: #fff;
    cursor: pointer;
  }
  .btn-search.active img, .btn-search:hover img {
    border: ${props => { return `2px solid ${props.theme.color.primaryColor}`}};
    border-bottom: none;
  }
`;
