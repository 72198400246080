import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, WebappContext, WhiteLabelContext} from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import SliderHpDesires from 'ToolboxComponents/commons/sliders/slider-homepage-desires/slider-homepage-desires';
import HomeSliderDesiresWrapper from 'ToolboxComponents/webapp/pages/home-view/components/home-slider-desires/home-slider-desires.style';
import getText from "ToolboxUtils/web/get-text";


const HomeSliderDesires = ({ dayTimes, desires, onDesireSelected }) => {
  const theme = useContext(ThemeContext).state;
  const [textualContent] = useContext(WhiteLabelContext).usePath('textualContent');
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const [resultsView, setResultsView] = useContext(WebappContext).usePath('resultsView');
  const [search, setSearch] = useContext(WebappContext).usePath('search');
  const [moment, setMoment] = useState(null);
  const [isMobile, setIsMobile] = useState(null);

  const resetSearch = () => {
    setSearch(null);
    setResultsView({
      selectedSlideId: null,
      activeProducts: null,
    })
  }

  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  useEffect(() => {
    const date = new Date();
    let localTime = date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
    if (localTime < 21600) {
      localTime = 83000;
    }
    for (const moment of dayTimes || []) {
      if(moment.startSecond < localTime && moment.endSecond >= localTime) {
        switch (moment.id) {
          case 1:
            setMoment('page.homepage.morning');
            break;
          case 2:
            setMoment('page.homepage.noon');
            break;
          case 3:
            setMoment('page.homepage.afternoon');
            break;
          case 4:
            setMoment('page.homepage.evening');
            break;
          case 5:
            setMoment('page.homepage.night');
            break;
          default:
            setMoment('page.homepage.today');
        }
      }
    }
  }, []);

  return (
    <HomeSliderDesiresWrapper className={`home-slider-desires`} theme={theme} isMobile={isMobile}>
      <div className={`block-title sub`}>
        <hr></hr>
        <div className={`title`}>
          <Text path={`page.homepage.desiresSliderTitle1`} />
        </div>
        <div className={`sub-title`}>
          <Text path={`page.homepage.desiresSliderTitle2`} data={{moment: getText(textualContent, moment)}} />
        </div>
      </div>
      <SliderHpDesires
        desires={desires}
        onDesireSelected={id => onDesireSelected(id)}
        onClick={() => {analytics.sendEnviesInteractionsHomepageEvent()}}
      />
       <div className="button-area">
        <Anchor code="search" onClick={() => { resetSearch(); analytics.sendVoirEnviesInteractionsHomepageEvent(); }}>
            <div className="button">
              <span className="button-text">
              <Text path={`page.homepage.desiresLink`} />
              </span>
            </div>
        </Anchor>
      </div>

    </HomeSliderDesiresWrapper>
  );
};

HomeSliderDesires.propTypes = {
  desires: PropTypes.array.isRequired,
  dayTimes: PropTypes.array.isRequired,
  onDesireSelected: PropTypes.func.isRequired,
};

export default HomeSliderDesires;
