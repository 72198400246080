import React, { useState, useEffect, useContext, useRef } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LifeWrapper = styled.div``;

const Life = props => {
  const theme = useContext(ThemeContext).state;
  const lifeDescriptionRef = useRef();
  const [isTextFull, setIsTextFull] = useState(false);
  const [
    lifeDescriptionOffsetHeight,
    setLifeDescriptionOffestHeight,
  ] = useState(0);
  const [
    lifeDescriptionScrollHeight,
    setLifeDescriptionScrollHeight,
  ] = useState(0);

  useEffect(() => {
    setLifeDescriptionOffestHeight(lifeDescriptionRef.current.offsetHeight);
    setLifeDescriptionScrollHeight(lifeDescriptionRef.current.scrollHeight);
  }, [lifeDescriptionRef]);

  useEffect(() => {
    setIsTextFull(false);
  }, [props.sliderIndex]);

  return (
    <LifeWrapper theme={theme} className={`life`}>
      <div className={`life-title`}>Sa bio</div>
      <div
        className={`life-description ${isTextFull === true && 'text-full'}`}
        ref={lifeDescriptionRef}
      >
        Je partage ma vie entre les plages Atlantiques et ma maison bordelaise.
        J'adore découvrir de bonnes tables en Médoc et les bordelaise. J'adore
        découvrir de bonnes tables en Médoc et leses en Médoc et leses en Médoc
        et leses en Médoc et leses en Médoc et leses en Médoc et leses en Médoc
        et leses en Médoc et leses en Médoc et leses en Médoc et leses en Médoc
        et les
      </div>
      <div
        className={`life-read-more ${isTextFull === true ? ' display-none' : ''}
              ${
                lifeDescriptionOffsetHeight < lifeDescriptionScrollHeight
                  ? ''
                  : ' display-none'
              }
              `}
        onClick={() => setIsTextFull(true)}
      >
        ... (lire +)
      </div>
    </LifeWrapper>
  );
};

Life.propTypes = {
  sliderIndex: PropTypes.number, // received when slide is swiped
};

export default Life;
