import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import styled from 'styled-components';
import { ThemeContext, WebappContext } from 'ToolboxUtils/web/context/context';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import UpcomingEventsHomeCard from 'ToolboxComponents/commons/cards/upcoming-events-home-card/upcoming-events-home-card';

const HomeSliderUpcomingEventsWrapper = styled.div`
  padding: 30px 10px 20px;

  .swiper-pagination-design {
    top: 20px;
    margin-bottom: 30px;
  }

  .swiper-pagination-bullet {
    background: ${props => props.tertiaryColor};
  }

  .home-slider-upcoming-events-title {
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight) + 300};
    text-align: center;
  }

  .home-slider-upcoming-events-main {
    font-family: ${props => props.theme.font.secondaryFont.name};
    text-transform: uppercase;
    font-size: ${props => `${20 * props.theme.font.secondaryFont.scaling / 100}px`};
  }

  .home-slider-upcoming-events-sub {
    color: ${props => props.primaryColor};
    margin-bottom: 20px;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    padding: 40px 20px;
  }
`;

const HomeSliderUpcomingEvents = props => {
  const theme = useContext(ThemeContext).state;
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const { upcomingEvents } = props;
  const { primaryColor, tertiaryColor } = useContext(ThemeContext).state.color;
  // const [activeProducts, setActiveProducts] = useState(products);

  return (
    <HomeSliderUpcomingEventsWrapper
      className={`home-slider-upcoming-events`}
      primaryColor={primaryColor}
      tertiaryColor={tertiaryColor}
      theme={theme}
    >
      <div className={`block-title sub`}>
        <hr></hr>
        <div className={`title`}>
          <Text path={`page.homepage.upcomingEventsTitle`} />
        </div>
        <div className={`sub-title`}>
          <Text path={`page.homepage.upcomingEventsSubtitle`} />
        </div>
      </div>
      <Slider
        mobile={{
          slidesPerView: 1,
          dots: true,
          spaceBetween: 20,
          slidesPadding: 12,
        }}
        desktop={{
          slidesPerView: 4,
          arrows: true,
          spaceBetween: 20,
          slidesPadding: 20,
          slidesPaddingBottom: 10,
          centerInsufficientSlides: true,
        }}
        dotsColorActive={primaryColor}
      >
        {upcomingEvents.map((upcomingEvent, index) => (
          <UpcomingEventsHomeCard
            onClick={() => { analytics.sendFMAInteractionsHomepageEvent() }}
            key={`upcoming-event-home-card--${index}`}
            upcomingEvent={upcomingEvent}
          />
        ))}
      </Slider>
    </HomeSliderUpcomingEventsWrapper>
  );
};

HomeSliderUpcomingEvents.propTypes = {};

export default HomeSliderUpcomingEvents;
