import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import { WebappContext, GeolocationContext, WhiteLabelContext, LanguageContext, AccountContext } from "ToolboxUtils/web/context/context";
import HomeView from 'ToolboxComponents/webapp/pages/home-view/home-view';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import Loader from 'ToolboxComponents/commons/loader/loader';
import getPath from 'Utils/web/get-path';

import { PAGE_LOAD_WAITING } from 'Params/globals';

const Home = props => {
  const history = useHistory();

  const [signIn, setSignIn] = useContext(WebappContext).usePath('modals.signIn');
  const [account, setAccount] = useContext(AccountContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [geolocation, setGeolocation] = useContext(GeolocationContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [search, setSearch] = useContext(WebappContext).usePath('search');
  const [results, setResults] = useContext(WebappContext).usePath('results');
  const [toast, setToast] = useContext(WebappContext).usePath('toast');
  const [products, setProducts] = useContext(WebappContext).usePath('home.products');
  const [restaurants, setRestaurants] = useContext(WebappContext).usePath('home.restaurants');
  const [activites, setActivites] = useContext(WebappContext).usePath('home.activites');
  const [sitesToVisit, setSitesToVisit] = useContext(WebappContext).usePath('home.sitesToVisit');
  const [fmaProducts, setFmaProducts] = useContext(WebappContext).usePath('home.fmaProducts');
  const [events, setEvents] = useContext(WebappContext).usePath('home.events');
  const [secrets, setSecrets] = useContext(WebappContext).usePath('home.secrets');
  const [coordinates] = useContext(GeolocationContext).usePath('coordinates');
  const [circuits, setCircuits] = useContext(WebappContext).usePath('home.circuits');
  const [majorsThemes, setMajorsThemes] = useContext(WebappContext).usePath('home.majorsThemes');
  const [heartstoppersThemes, setHeartstoppersThemes] = useContext(WebappContext).usePath('home.heartstoppersThemes');
  const [blocks, setBlocks] = useState();
  const [hasHomePageProducts, setHasHomePageProducts] = useState();
  const [hasSecrets, setHasSecrets] = useState();
  const [hasEvents, setHasEvents] = useState();
  const [hasFma, setHasFma] = useState();
  const [hasCircuits, setHasCircuits] = useState();
  const [hasMajorsThemes, setHasMajorsThemes] = useState();
  const [hasHeartstoppersThemes, setHasHeartstoppersThemes] = useState();
  const [hasRestaurants, setHasRestaurants] = useState(); 
  const [hasActivites, setHasActivites] = useState();
  const [hasSitesToVisit, setHasSitesToVisit] = useState();
  const [restoDesireName,setRestoDesireName]= useContext(WebappContext).usePath('home.restoDesireName');
  const [activitesDesireName,setActivitesDesireName]= useContext(WebappContext).usePath('home.activitesDesireName');
  const [sitesDesireName,setSitesDesireName]= useContext(WebappContext).usePath('home.sitesDesireName');
  const [modals, setModals] = useContext(WebappContext).usePath('modals');
  const [isPreviousGeolocated, setIsPreviousGeolocated] = useContext(WebappContext).usePath('home.isPreviousGeolocated');

  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);


  useEffect(() => {
    const blocks = whiteLabel.pages.filter(x => x.code === 'home')[0].blocks;
    setBlocks(blocks);
    setHasHomePageProducts(blocks.includes(1));
    setHasSecrets(blocks.includes(2));
    setHasEvents(blocks.includes(4));
    setHasFma(blocks.includes(3));
    setHasCircuits(blocks.includes(7));
    setHasRestaurants(blocks.includes(14));
    setHasActivites(blocks.includes(15));
    setHasSitesToVisit(blocks.includes(16));
    setHasMajorsThemes(blocks.includes(17));
    setHasHeartstoppersThemes(blocks.includes(18));
  }, [whiteLabel]);
  
  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!(!!products && !!secrets &&  desires.isComplete && dayTimes.isComplete && whisperers.isComplete)) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  const updateFav = id => {
    if (account.favorites.includes(id)) {
      const favorites = account.favorites.filter(favorite => favorite !== id);
      setAccount('favorites', favorites);
    } else {
      const favorites = account.favorites.concat([id]);
      setAccount('favorites', favorites);
    }
  }

  const openSignInModal = status => {
    setSignIn({opened: true, from: 'fav'});
  }

  const sliderProducts = useApiChuchoteurs.get('/products', {
    params: {filter: 'homepage-products', language, longitude: geolocation.coordinates.longitude, latitude: geolocation.coordinates.latitude, whiteLabelId: whiteLabel.id, isInZone: whiteLabel.isInZone},
    depends: !!(whiteLabel && geolocation && !(products && geolocation.isGeolocated === isPreviousGeolocated) && hasHomePageProducts),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setProducts(data);
     }
  });

  // Recuperation des restaurants, activités et sites à visiter pour Saintonge 
  const sliderRestaurants = useApiChuchoteurs.get('/products', {
    params: {filter: 'restaurants', language, longitude: geolocation.coordinates.longitude, latitude: geolocation.coordinates.latitude, whiteLabelId: whiteLabel.id, isInZone: whiteLabel.isInZone},
    depends: !!(whiteLabel && geolocation && !(products && geolocation.isGeolocated === isPreviousGeolocated) && hasRestaurants),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setRestoDesireName(data[0].desireName)
      setRestaurants(data);
     }
  });
  
  const sliderActivites = useApiChuchoteurs.get('/products', {
    params: {filter: 'activites', language, longitude: geolocation.coordinates.longitude, latitude: geolocation.coordinates.latitude, whiteLabelId: whiteLabel.id, isInZone: whiteLabel.isInZone},
    depends: !!(whiteLabel && geolocation && !(products && geolocation.isGeolocated === isPreviousGeolocated) && hasActivites),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setActivitesDesireName(data[0].desireName)
      setActivites(data);
     }
  });
  

  const sliderSitesToVisit = useApiChuchoteurs.get('/products', {
    params: {filter: 'sites-to-visit', language, longitude: geolocation.coordinates.longitude, latitude: geolocation.coordinates.latitude, whiteLabelId: whiteLabel.id, isInZone: whiteLabel.isInZone},
    depends: !!(whiteLabel && geolocation && !(products && geolocation.isGeolocated === isPreviousGeolocated) && hasSitesToVisit),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setSitesDesireName(data[0].desireName)
      setSitesToVisit(data);
     }
  });
  
  const sliderSecrets = useApiChuchoteurs.get('/secrets', {
    params: { filter: 'homepage-secrets', isInZone: whiteLabel.isInZone, longitude: geolocation.coordinates.longitude, latitude: geolocation.coordinates.latitude, language: language, whiteLabelId: whiteLabel.id, limit: 4},
    depends: !!(whiteLabel && geolocation && !(secrets && geolocation.isGeolocated === isPreviousGeolocated) && hasSecrets),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setSecrets(data);
     }
  });


  const sliderFmaProducts = useApiChuchoteurs.get('/products', {
    params: {filter: 'homepage-fma-products', language, longitude: geolocation.coordinates.longitude, latitude: geolocation.coordinates.latitude, whiteLabelId: whiteLabel.id, isInZone: whiteLabel.isInZone},
    depends: !!(whiteLabel && geolocation && !(fmaProducts && geolocation.isGeolocated === isPreviousGeolocated) && hasFma),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setFmaProducts(data);
     }
  });

  const sliderEvents = useApiChuchoteurs.get('/events', {
    params: {
      filter: 'homepage', 
      language, 
      algo: whiteLabel.config.algo && whiteLabel.config.algo.event, 
      isGeolocated: geolocation.isGeolocated, 
      longitude: geolocation.coordinates.longitude, 
      latitude: geolocation.coordinates.latitude, 
      whiteLabelId: whiteLabel.id, 
      eventId: whiteLabel.currentEvent && whiteLabel.currentEvent.id
    },
    depends: !!(whiteLabel && whiteLabel.currentEvent !== undefined && geolocation && !(events && geolocation.isGeolocated === isPreviousGeolocated) && hasEvents),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setEvents(data);
     } 
    
  });
  
  const sliderCircuits = useApiChuchoteurs.get('/products', {
    params: {filter: 'circuits', language: language, whiteLabelId: whiteLabel.id, latitude: coordinates.latitude,
    longitude: coordinates.longitude},
    depends: !!(whiteLabel && geolocation && !(circuits && geolocation.isGeolocated === isPreviousGeolocated) && hasCircuits),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setCircuits(data);
     }
  });

  const sliderMajorsThemes = useApiChuchoteurs.get('/products', {
    params: {filter: 'homepage-majors-themes', language: language, whiteLabelId: whiteLabel.id, latitude: coordinates.latitude,
    longitude: coordinates.longitude},
    depends: !!(whiteLabel && geolocation && !(circuits && geolocation.isGeolocated === isPreviousGeolocated) && hasMajorsThemes),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setMajorsThemes(data);
     }
  });

  const sliderHeartstoppersThemes = useApiChuchoteurs.get('/products', {
    params: {filter: 'homepage-heartstoppers-themes', language: language, parentWhiteLabelId: whiteLabel.id ,whiteLabelId: whiteLabel.privatePartnerId, latitude: coordinates.latitude,
    longitude: coordinates.longitude},
    depends: !!(whiteLabel && geolocation && !(circuits && geolocation.isGeolocated === isPreviousGeolocated) && hasHeartstoppersThemes),
    onComplete: ({data}) => {
      setIsPreviousGeolocated(geolocation.isGeolocated);
      setHeartstoppersThemes(data);
     }
  });
  
  const desires = useApiChuchoteurs.get('/desires', {
    params: {language: language, whiteLabelId: whiteLabel.id, cityId: whiteLabel.city.id, filter: 'search-desires', limit: 4},
  });

  const dayTimes = useApiChuchoteurs.get('/moments', {
    params: {filter: 'all'},
  });
  useIsContentLoaded((!!products || !hasHomePageProducts) && (!!secrets || !hasSecrets) &&  desires.isComplete && dayTimes.isComplete);
  return (
    <>
      {whiteLabel && blocks
        ? <HomeView
            nbSecrets={whiteLabel.nbSecrets}
            nbWhisperers={whiteLabel.nbWhisperers}
            blocks={blocks}
            products={products}
            restaurants={restaurants}
            activites={activites}
            sitesToVisit={sitesToVisit}
            majorsThemes={majorsThemes}
            heartstoppersThemes={heartstoppersThemes}
            secrets={secrets}
            currentEvent={whiteLabel.currentEvent}
            events={events}
            circuits={circuits}
            upcomingEvents={fmaProducts}
            desires={desires.data}
            dayTimes={dayTimes.data}
            whisperers={whiteLabel.starWhisperers}
            hasStarWhisperers={whiteLabel.hasStarWhisperers}
            prehomeSecrets={whiteLabel.prehomeSecrets}
            activitesDesireName={activitesDesireName}
            restoDesireName={restoDesireName}
            sitesDesireName={sitesDesireName}
            onDesireSelected={async (id) => {
              try {
                setSearch('desireId', id);
                setSearch('momentId', 1);
                setSearch('locationId', whiteLabel.city.id);
                setSearch('transportId', account && account.transportId ? account.transportId : 2,);
                setSearch('profileId', account && account.profileId ? account.profileId : 1);
                setSearch('budgetId', 1);
                setSearch('userLatitude', geolocation.coordinates.latitude);
                setSearch('userLongitude', geolocation.coordinates.longitude);
                setSearch('searchLatitude', whiteLabel.latitude);
                setSearch('searchLongitude', whiteLabel.longitude);
                setSearch('isInZone', whiteLabel.isInZone);
                setSearch('isGeolocated', geolocation.isGeolocated);
                const products = await apiChuchoteurs.get('/products/', {
                  params: {
                    desireId: id,
                    momentId: 1,
                    locationId: whiteLabel.city.id,
                    transportId: account && account.transportId ? account.transportId : 2,
                    profileId: account && account.profileId ? account.profileId : 1,
                    budgetId: 1,
                    userLatitude: geolocation.coordinates.latitude,
                    userLongitude: geolocation.coordinates.longitude,
                    searchLatitude: whiteLabel.latitude,
                    searchLongitude: whiteLabel.longitude,
                    isInZone: whiteLabel.isInZone,
                    isGeolocated: geolocation.isGeolocated,
                    language: language,
                    whiteLabelId: whiteLabel.id,
                    filter: 'search-products',
                    time: new Date().getTime() // since we pass a momentId, we use time to tell that the request may lead to different result
                  }
                });
                setResults(products);
                history.push(getPath('search', whiteLabel, language));
              } catch (err) {
                apiChuchoteurs.post(`/logs`, {
                  body: {error: {...err, type: 'front'}}
                })
              }
            }}
            addFavorite={async (id) => {
              if (account && account.id) {
                try {
                  const favorite = await apiChuchoteurs.post(`/accounts/${account.id}/products/${id}/favorites`, {
                    token: localStorage.token
                  })
                  updateFav(id);
                } catch(err) {
                  apiChuchoteurs.post(`/logs`, {
                    body: {error: {...err, type: 'front'}}
                  })
                }
              } else {
                openSignInModal(true);
              }
            }}
            deleteFavorite={async (id) => {
              if (account && account.id) {
                try {
                  const favorite = await apiChuchoteurs.delete(`/accounts/${account.id}/products/${id}/favorites`, {
                    token: localStorage.token
                  })
                  updateFav(id);
                } catch(err) {
                  apiChuchoteurs.post(`/logs`, {
                    body: {error: {...err, type: 'front'}}
                  })
                }
              } else {
                openSignInModal(true);
              }
            }}
          />
        : isLoaderDisplayed
          ? <Loader />
          : null
      }
    </>
  );
}

export default Home;
