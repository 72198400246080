import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const innerHeight = window.innerHeight;

const PrehomeWrapper = styled.div`
  background: #ffffff;
  height: ${innerHeight - 60 - 62}px;
  display: flex;
  flex-flow: column;
  align-item: center;
  justify-content: flex-end;
  box-shadow: 0 5px 36px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 40px;

  .arrow {
    display: none;
    position:absolute;
    top: calc((100vh) / 2 - 35px - 18px);
    cursor: pointer;
  }

  .arrow::after {
    color: white;
    opacity: 70%;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    font-family: swiper-icons;
    font-size: 70px;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }

  .arrow.left-arrow {
    left : 20px;
  }

  .arrow.right-arrow {
    right : 20px;
  }

  .arrow.left-arrow::after {
    content: 'previous';
  }

  .arrow.right-arrow::after {
    content: 'next';
  }

  .prehome-hero-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    font-size: 94px;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    line-height: 0.8;
    box-shadow: 0 2px 6px rgba(17, 17, 17, 0.4);
    position: relative;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    h1 {
      line-height: 0.8;
      font-size: 90px;
    }

    .title {
      position: absolute;
      z-index:1;
      ${props => props.hasStarWhisperers ? '' : 'margin-bottom:20px'};
      padding-left: 10px;

      .secret-count {
        text-shadow: 1px 2px 8px rgba(17, 17, 17, 0.4);
        font-weight: 600;
      }
      .text {
        display:block;
        text-shadow: 1px 2px 6px rgba(17, 17, 17, 0.4);
        font-weight: 500;
        letter-spacing: -3.5px;
        font-size: 4.7rem;
        padding-right: 8px;
      }
    }
  }

  .prehome-banner {
    display: flex;
    flex-flow: wrap;
    padding: 12px 10px 10px;
    height: 204px;
    margin-bottom: ${props => props.hasStarWhisperers ? '0' : '20px'};

    .title {
      display:block;
      font-size: 17px;
      line-height: 1;
      margin-bottom: 15px;
    }
    .users {
      width: 100%;
      height: 90px;
      display: flex;
      justify-content: space-between;

      .user {
        .image {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          border: 5px solid #ffffff;
          overflow: hidden;
          box-shadow: 0 3px 6px rgba(17, 17, 17, 0.4);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .name {
          text-align: center;
          font-size: 10px;
          margin-top: 5px;
        }
      }
      .whisperer-badge {
        position: relative;
        margin-left: 10px;

        .user-count {
          font-size: 12.5px;
          position: absolute;
          left: -22px;
          bottom: 0;
        }

        .badge {
          img {
            width: 80px;
            height: 80px;
            transform: rotate(20deg);
            border-radius: 50%;
            box-shadow: 0 3px 6px rgba(17, 17, 17, 0.4);
          }
        }
      }
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    height: calc(100vh - 80px);
    margin-bottom: ${props => props.hasStarWhisperers ? '0' : '20px'};;
    width: calc(100vw - ${props => props.scrollbarWidth / 2}px);

    .arrow {
      display: block;
    }

    .prehome-hero-image {
      justify-content: center;

      .title {
        padding-left: 0;
        margin-bottom: ${props => props.hasStarWhisperers ? '50px' : '200px'};

        .secret-count {
        }
        .text {
        }
      }
    }

    .prehome-banner {
      padding: 20px 10px 10px
      height: 230px;

      .title {
        font-size: 28px;
        margin-bottom: 0;
      }

      .users {
        height: 130px;
        align-items: flex-end;

        .user {
          .image {
            width: 90px;
            height: 90px;

            img {
            }
          }
          .name {
            font-size: 14px;
          }

          &:first-child {
            margin-right: 20px;
          }
        }
        .whisperer-badge {
          margin-left: 50px;

          .user-count {
            font-size: 20px;
            left: -36px;
          }

          .badge {
            img {
              width: 125px;
              height: 125px;
            }
          }
        }
      }
    }
  }

  /* manage the title on mobiles 
  @media (max-height: 615px) {
    .prehome-hero-image {
      display: flex;
      align-items: center;

      .title {
        margin-bottom: 0;
      }
    }
  }*/

  @media (max-width: 320px) {
    .prehome-hero-image {
      .title {
        font-size: 54px;
        .text {
          font-size: 60px;
        }
      }
    }
  }
`;

export default PrehomeWrapper;
