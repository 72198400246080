import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImageWrapper = styled.div``;

const Image = props => {
  return (
    <ImageWrapper className={`image`}>
      <img src={`https://avatars.dicebear.com/v2/avataaars/dsssdsd.svg?options[eyes][]=defaultValue&options[mouth][]=smile&options[background]=%23dcdcdc`} alt={``} />
    </ImageWrapper>
  );
};

Image.propTypes = {};

export default Image;
