import styled from 'styled-components';

export const MomentsHomeCardWrapper = styled.div`
  border-radius: 8px;
  box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.1);
  background: white;
  max-width: 350px;
  margin: 0 20px;

  &:hover {
    cursor: pointer;
  }

  .card-link {
    display: block;
    padding: 10px;
  }

  .line-clamp-1,
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .line-clamp-1 {
    -webkit-line-clamp: 1;
  }
  .line-clamp-2 {
    -webkit-line-clamp: 2;
  }

  .moments-home-card-image {
    width: 100%;
    position: relative;
    margin-bottom: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      border-radius: 8px;
    }

    &:after {
      content: '';
      padding-bottom: 100%;
      display: block;
    }
  }

  .moments-home-card-title {
    font-size: ${props => `${20*props.theme.font.primaryFont.scaling/100}px`};
  }

  .moments-home-card-nb-steps {
    color: ${props => props.theme.color.primaryColor};
  }
`;
