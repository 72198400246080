import React, { Suspense, useContext } from 'react';
// import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import isMobileView , { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import styled from 'styled-components';



const HomeTitleWrapper = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 15px 0 20px;
  
  .title {
    font-size: ${props => `${19*props.theme.font.primaryFont.scaling/100}px`};
    text-align: center;
    width: 100%;
  }

  strong {
    color: ${props => props.theme.color.primaryColor};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .title {
      font-size: ${props => `${28*props.theme.font.primaryFont.scaling/100}px`};
    }
  }
`;

const HomeTitle = props => {
  const theme = useContext(ThemeContext).state;
  return (
      <HomeTitleWrapper className={`title-block`} theme={theme}>
          <h2 className={`title`}>
            <Text path={`page.homepage.title`} />
          </h2>
      </HomeTitleWrapper> 
  );
};

HomeTitle.propTypes = {};

export default HomeTitle;
